define("discourse/plugins/uge-discourse-plugin/discourse/templates/components/sidebar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{body-class "has-sidebar-page"}}
  
  <section
    {{did-insert this.registerListener}}
    {{will-destroy this.unregisterListener}}
    id="d-sidebar"
    class="sidebar-container"
  >
    {{#if this.isQuestionHub}}
      <QuestionHubSidebar />
    {{else}}
      {{#if this.showSwitchPanelButtonsOnTop}}
        <Sidebar::SwitchPanelButtons @buttons={{this.switchPanelButtons}} />
      {{/if}}
  
      {{#if this.sidebarState.showMainPanel}}
        <Sidebar::Sections
          @currentUser={{this.currentUser}}
          @collapsableSections={{true}}
          @panel={{this.sidebarState.currentPanel}}
        />
      {{else}}
        <Sidebar::ApiPanels
          @currentUser={{this.currentUser}}
          @collapsableSections={{true}}
        />
      {{/if}}
  
      {{#unless this.showSwitchPanelButtonsOnTop}}
        <Sidebar::SwitchPanelButtons @buttons={{this.switchPanelButtons}} />
      {{/unless}}
  
      <Sidebar::Footer />
    {{/if}}
  
  </section>
  */
  {
    "id": "G1LueX8/",
    "block": "[[[1,[28,[35,0],[\"has-sidebar-page\"],null]],[1,\"\\n\\n\"],[11,\"section\"],[24,1,\"d-sidebar\"],[24,0,\"sidebar-container\"],[4,[38,1],[[30,0,[\"registerListener\"]]],null],[4,[38,2],[[30,0,[\"unregisterListener\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"isQuestionHub\"]],[[[1,\"    \"],[8,[39,4],null,null,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"showSwitchPanelButtonsOnTop\"]],[[[1,\"      \"],[8,[39,5],null,[[\"@buttons\"],[[30,0,[\"switchPanelButtons\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"sidebarState\",\"showMainPanel\"]],[[[1,\"      \"],[8,[39,6],null,[[\"@currentUser\",\"@collapsableSections\",\"@panel\"],[[30,0,[\"currentUser\"]],true,[30,0,[\"sidebarState\",\"currentPanel\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,7],null,[[\"@currentUser\",\"@collapsableSections\"],[[30,0,[\"currentUser\"]],true]],null],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[51,[30,0,[\"showSwitchPanelButtonsOnTop\"]]],[[[1,\"      \"],[8,[39,5],null,[[\"@buttons\"],[[30,0,[\"switchPanelButtons\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[8,[39,9],null,null,null],[1,\"\\n\"]],[]]],[1,\"\\n\"],[13]],[],false,[\"body-class\",\"did-insert\",\"will-destroy\",\"if\",\"question-hub-sidebar\",\"sidebar/switch-panel-buttons\",\"sidebar/sections\",\"sidebar/api-panels\",\"unless\",\"sidebar/footer\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/components/sidebar.hbs",
    "isStrictMode": false
  });
});