define("discourse/plugins/uge-discourse-plugin/discourse/initializers/modify-model-composer", ["exports", "discourse/lib/plugin-api", "discourse/plugins/uge-discourse-plugin/discourse/utils/constants", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _pluginApi, _constants, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: "modify-model-composer",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        var _dec, _obj;
        api.modifyClass("model:composer", (_dec = (0, _decorators.default)("loading", "canEditTitle", "titleLength", "targetRecipients", "targetRecipientsArray", "replyLength", "categoryId", "missingReplyCharacters", "tags", "topicFirstPost", "minimumRequiredTags", "user.staff"), (_obj = {
          pluginId: _constants.PLUGIN_ID,
          cantSubmitPost(loading, canEditTitle, titleLength, targetRecipients, targetRecipientsArray, replyLength, categoryId, missingReplyCharacters, tags, topicFirstPost, minimumRequiredTags, isStaffUser) {
            // can't submit while loading
            if (loading) {
              return true;
            }

            // title is required when
            //  - creating a new topic/private message
            //  - editing the 1st post
            if (canEditTitle && !this.titleLengthValid) {
              return true;
            }

            // reply is always required
            if (missingReplyCharacters > 0) {
              return true;
            }
            if (replyLength > this.siteSettings.max_post_length) {
              return true;
            }
            if (this.site.can_tag_topics && !isStaffUser && topicFirstPost && minimumRequiredTags) {
              const tagsArray = tags || [];
              if (tagsArray.length < minimumRequiredTags) {
                return true;
              }
            }
            if (topicFirstPost) {
              // user should modify topic template
              const category = this.category;
              if (category && category.topic_template) {
                if (this.reply.trim() === category.topic_template.trim()) {
                  this.dialog.alert(_discourseI18n.default.t("composer.error.topic_template_not_modified"));
                  return true;
                }
              }
            }
            if (this.privateMessage) {
              // need at least one user when sending a PM
              return targetRecipients && targetRecipientsArray.length === 0;
            } else {
              // has a category? (when needed)
              return this.requiredCategoryMissing;
            }
          }
        }, (_applyDecoratedDescriptor(_obj, "cantSubmitPost", [_dec], Object.getOwnPropertyDescriptor(_obj, "cantSubmitPost"), _obj)), _obj)));
      });
    }
  };
});