define("discourse/plugins/uge-discourse-plugin/discourse/initializers/modify-route-application", ["exports", "discourse/lib/plugin-api", "discourse/plugins/uge-discourse-plugin/discourse/utils/constants", "discourse/lib/ajax", "@ember/application"], function (_exports, _pluginApi, _constants, _ajax, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "modify-route-application",
    initialize(owner) {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        api.modifyClass("route:application", {
          pluginId: _constants.PLUGIN_ID,
          model() {
            const appEvents = owner.lookup("service:app-events");
            appEvents.on("page:changed", data => {
              if (this.currentUser && (this.currentUser.admin || this.currentUser.moderator)) {
                return;
              }
              if (!this.currentUser) {
                const applicationRoute = (0, _application.getOwner)(this).lookup("route:application");
                applicationRoute.send("showLogin");
              }
              const listExceptionRoutes = this.siteSettings?.exception_routes_not_blocked_for_normal_user?.split("|");
              if (data?.currentRouteName.includes("userActivity") || data?.currentRouteName.includes("user")) {
                const clickedUsername = data.url.split("/")[2];
                if (clickedUsername) {
                  (0, _ajax.ajax)(`/u/${clickedUsername}/card.json`).then(res => {
                    if (res.user?.external_id) {
                      window.open(`${this.siteSettings.uge_url}/profile/${res.user.external_id}`, "_self");
                    }
                  });
                }
              } else if (this.siteSettings?.block_routes_for_normal_user_enabled) {
                if (!listExceptionRoutes.find(route => data?.currentRouteName.includes(route))) {
                  if (this.siteSettings.uge_url) {
                    window.open(this.siteSettings.uge_url, "_self");
                  }
                }
              }
            });
          }
        });
        api.modifyClass("controller:application", {
          pluginId: _constants.PLUGIN_ID,
          showSidebarRedirect: false,
          init() {
            this._super(...arguments);
            const appEvents = owner.lookup("service:app-events");
            appEvents.on("page:changed", data => {
              if (this.currentUser && (this.currentUser.admin || this.currentUser.moderator)) {
                this.set("showSidebarRedirect", true);
                return;
              }
              if (!this.currentUser) {
                const applicationRoute = (0, _application.getOwner)(this).lookup("route:application");
                applicationRoute.send("showLogin");
              }
              const listExceptionRoutes = this.siteSettings?.exception_routes_not_blocked_for_normal_user?.split("|");
              if (data?.currentRouteName.includes("userActivity") || data?.currentRouteName.includes("user")) {
                this.set("showSidebarRedirect", false);
                return;
              } else if (this.siteSettings?.block_routes_for_normal_user_enabled) {
                if (!listExceptionRoutes.find(route => data?.currentRouteName.includes(route))) {
                  this.set("showSidebarRedirect", false);
                  return;
                }
              }
              this.set("showSidebarRedirect", true);
              return;
            });
          }
        });
      });
    }
  };
});