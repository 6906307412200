define("discourse/plugins/uge-discourse-plugin/discourse/components/question-hub-mobile-tabs", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/url", "discourse-i18n"], function (_exports, _component, _tracking, _object, _service, _url, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let _class = _exports.default = (_class2 = class _class2 extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "appEvents", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
      _initializerDefineProperty(this, "tabs", _descriptor4, this);
      _initializerDefineProperty(this, "tabActive", _descriptor5, this);
      _initializerDefineProperty(this, "optionSubTabs", _descriptor6, this);
      _initializerDefineProperty(this, "subTabActive", _descriptor7, this);
      this.appEvents.on("page:changed", this, this._routeChanged);
      if (this.currentUser) {
        const isStaffOrOrganizer = this.currentUser.staff || this.args.organizerId && this.args.organizerId.includes(this.currentUser.id);
        const myQuestionSubTabs = [{
          id: 21,
          name: _discourseI18n.default.t("uge_discourse_plugin.posted"),
          path: "my-questions/posted"
        }];
        if (!isStaffOrOrganizer) {
          myQuestionSubTabs.push(
          // {
          //   id: 22,
          //   name: I18n.t("uge_discourse_plugin.rejected"),
          //   path: "my-questions/rejected",
          // },
          {
            id: 23,
            name: _discourseI18n.default.t("uge_discourse_plugin.pending"),
            path: "my-questions/pending"
          });
        }
        this.tabs.push({
          id: 2,
          name: _discourseI18n.default.t("uge_discourse_plugin.my_questions"),
          path: "my-questions",
          subTabs: myQuestionSubTabs
        });
        if (isStaffOrOrganizer) {
          this.tabs.push({
            id: 3,
            name: _discourseI18n.default.t("uge_discourse_plugin.requests"),
            path: "requests",
            countField: "requestCount"
          });
        }
      }
      this.setActiveTab();
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off("page:changed", this, this._routeChanged);
    }
    _routeChanged() {
      this.setActiveTab();
    }
    setActiveTab() {
      const currentRoute = this.router.currentRouteName;
      if (["question-hub.index", "question-hub.all-questions"].includes(currentRoute)) {
        this.tabActive = 1;
        this.subTabActive = null;
        this.optionSubTabs = [];
      } else if (currentRoute.includes("question-hub.request")) {
        this.tabActive = 3;
        this.subTabActive = null;
        this.optionSubTabs = [];
      } else if (currentRoute.includes("question-hub.my-questions")) {
        this.tabActive = 2;
        this.optionSubTabs = this.getOptionSubTabs(this.tabs, this.tabActive);
        if (currentRoute.includes("my-questions-rejected")) {
          this.subTabActive = 22;
        } else if (currentRoute.includes("my-questions-pending")) {
          this.subTabActive = 23;
        } else {
          this.subTabActive = 21;
        }
      }
    }
    onChangeSubTabs(subTabId) {
      this.subTabActive = subTabId;
      const url = `/tournament/${this.args.category?.slug}/${this.args.category?.id}/${this.getSubTabPath(this.optionSubTabs, subTabId)}`;
      _url.default.routeTo(url);
    }

    // PRIVATE METHOD
    getOptionSubTabs(tabs, tabId) {
      const tab = tabs.find(tab => tab.id == tabId);
      if (tab) {
        return tab.subTabs;
      }
      return [];
    }
    getSubTabPath(optionSubTabs, subTabId) {
      const subTab = optionSubTabs.find(subTab => subTab.id == subTabId);
      return subTab.path || "";
    }
    // END PRIVATE METHOD
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "appEvents", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "tabs", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [{
        id: 1,
        name: _discourseI18n.default.t("uge_discourse_plugin.all_questions"),
        path: "all-questions"
      }];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "tabActive", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "optionSubTabs", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "subTabActive", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "onChangeSubTabs", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onChangeSubTabs"), _class2.prototype)), _class2);
});