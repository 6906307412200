define("discourse/plugins/uge-discourse-plugin/discourse/templates/components/question-hub-filter-bar", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="question-hub-filter-bar">
    <div class="filter-sort-wrapper">
      <div>
        <UgeTagDrop
          @currentCategory={{this.category}}
          @tagId={{this.tagId}}
          @onChangeTag={{onChangeTag}}
        />
      </div>
      <div class="divider-vertical" />
      <div>
        <UgeSingleSelect
          @value={{this.resolvedOrder}}
          @content={{this.ugeListOrders}}
          @onChange={{this.onChangeOrder}}
          @options={{hash caretDownIcon="caret-down" caretUpIcon="caret-down"}}
        />
      </div>
    </div>
    <div>
      <SearchQuestions @currentCategory={{this.category}} />
    </div>
  </div>
  */
  {
    "id": "IVjenrVp",
    "block": "[[[10,0],[14,0,\"question-hub-filter-bar\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"filter-sort-wrapper\"],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@currentCategory\",\"@tagId\",\"@onChangeTag\"],[[30,0,[\"category\"]],[30,0,[\"tagId\"]],[30,0,[\"onChangeTag\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"divider-vertical\"],[12],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@value\",\"@content\",\"@onChange\",\"@options\"],[[30,0,[\"resolvedOrder\"]],[30,0,[\"ugeListOrders\"]],[30,0,[\"onChangeOrder\"]],[28,[37,2],null,[[\"caretDownIcon\",\"caretUpIcon\"],[\"caret-down\",\"caret-down\"]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@currentCategory\"],[[30,0,[\"category\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `onChangeTag` property path was used in the `discourse/plugins/uge-discourse-plugin/discourse/templates/components/question-hub-filter-bar.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.onChangeTag}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"uge-tag-drop\",\"uge-single-select\",\"hash\",\"search-questions\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/components/question-hub-filter-bar.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});