define("discourse/plugins/uge-discourse-plugin/discourse/helpers/custom-format-date", ["exports", "@ember/template", "discourse-common/lib/helpers"], function (_exports, _template, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = customFormatDate;
  (0, _helpers.registerRawHelper)("custom-format-date", customFormatDate);
  function customFormatDate(val) {
    let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (val) {
      let date = new Date(val);

      // Chuyển đổi sang định dạng mm/dd/yyyy hh:mm
      var formattedDate = ("0" + (date.getMonth() + 1)).slice(-2) + "/" + ("0" + date.getDate()).slice(-2) + "/" + date.getFullYear() + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
      return (0, _template.htmlSafe)(formattedDate);
    }
  }
});