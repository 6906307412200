define("discourse/plugins/uge-discourse-plugin/discourse/templates/components/question-hub-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="question-hub-header">
    <div class="new-topic-wrapper">
      <div class="header-title">{{i18n "uge_discourse_plugin.question_hub"}}</div>
      <MountWidget @widget="uge-new-topic-button" />
    </div>
  </div>
  */
  {
    "id": "IYjX1VgW",
    "block": "[[[10,0],[14,0,\"question-hub-header\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"new-topic-wrapper\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"header-title\"],[12],[1,[28,[35,0],[\"uge_discourse_plugin.question_hub\"],null]],[13],[1,\"\\n    \"],[8,[39,1],null,[[\"@widget\"],[\"uge-new-topic-button\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"mount-widget\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/components/question-hub-header.hbs",
    "isStrictMode": false
  });
});