define("discourse/plugins/uge-discourse-plugin/discourse/templates/redirect-page", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="custom-not-found">
    {{loading-spinner}}
    <p>{{i18n "uge_discourse_plugin.redirect_page.loading"}}</p>
  </div>
  */
  {
    "id": "38QRx/5G",
    "block": "[[[10,0],[14,0,\"custom-not-found\"],[12],[1,\"\\n  \"],[41,[28,[32,0],[\"loading-spinner\"],null],[[[8,[39,1],null,null,null]],[]],[[[44,[[28,[37,3],null,[[\"loading-spinner\"],[[28,[32,1],[\"loading-spinner\"],null]]]]],[[[1,[52,[30,1,[\"loading-spinner\"]],[28,[30,1,[\"loading-spinner\"]],null,null],[28,[32,2],[[30,0],\"loading-spinner\",\"[\\\"The `loading-spinner` property path was used in the `discourse/plugins/uge-discourse-plugin/discourse/templates/redirect-page.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.loading-spinner}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[1,\"\\n  \"],[10,2],[12],[1,[28,[35,4],[\"uge_discourse_plugin.redirect_page.loading\"],null]],[13],[1,\"\\n\"],[13]],[\"maybeHelpers\"],false,[\"if\",\"loading-spinner\",\"let\",\"hash\",\"i18n\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/redirect-page.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});