define("discourse/plugins/uge-discourse-plugin/discourse/components/question-hub-header-mobile", ["exports", "discourse/plugins/uge-discourse-plugin/discourse/components/question-hub-sidebar"], function (_exports, _questionHubSidebar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _questionHubSidebar.default {
    get tournamentLogoUrl() {
      return this.category?.uploaded_logo?.url || "/plugins/uge-discourse-plugin/images/question-hub-logo-56.png";
    }
  }
  _exports.default = _default;
});