define("discourse/plugins/uge-discourse-plugin/discourse/helpers/avatar-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.avatarUrl = avatarUrl;
  function avatarUrl(template, rawSize) {
    if (!template) {
      return "";
    }
    const templatedPath = template.replace(/\{size\}/g, rawSize);
    return templatedPath;
  }
});