define("discourse/plugins/uge-discourse-plugin/discourse/templates/components/composer-action-title", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="question-composer action-title">
    {{this.actionTitle}}
  </span>
  */
  {
    "id": "93DpfTTg",
    "block": "[[[10,1],[14,0,\"question-composer action-title\"],[12],[1,\"\\n  \"],[1,[30,0,[\"actionTitle\"]]],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/components/composer-action-title.hbs",
    "isStrictMode": false
  });
});