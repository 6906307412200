define("discourse/plugins/uge-discourse-plugin/discourse/question-hub-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("question-hub", {
      path: "/tournament"
    }, function () {
      //question hub child route
      this.route("index", {
        path: "/*category_slug_path_with_id"
      });
      this.route("all-questions", {
        path: "/*category_slug_path_with_id/all-questions"
      });
      this.route("my-questions", {
        path: "/*category_slug_path_with_id/my-questions"
      });
      this.route("my-questions-posted", {
        path: "/*category_slug_path_with_id/my-questions/posted"
      });
      this.route("my-questions-pending", {
        path: "/*category_slug_path_with_id/my-questions/pending"
      });
      this.route("request", {
        path: "/*category_slug_path_with_id/requests"
      });
      this.route("create-question", {
        path: "/*category_slug_path_with_id/create-question"
      });
    });
    this.route("redirect-page", {
      path: "/redirect-page"
    });
  }
});