define("discourse/plugins/uge-discourse-plugin/discourse/templates/components/my-questions-topic-list-rejected", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <thead class="topic-list-header">
    {{raw "my-questions-topic-list-rejected-header"}}
  </thead>
  
  <tbody class="topic-list-body">
    {{#each this.filteredTopics as |topic index|}}
      <MyQuestionsTopicListRejectedItem @topic={{topic}} />
    {{/each}}
  </tbody>
  */
  {
    "id": "8CAA/4i3",
    "block": "[[[10,\"thead\"],[14,0,\"topic-list-header\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"my-questions-topic-list-rejected-header\"],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"tbody\"],[14,0,\"topic-list-body\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"filteredTopics\"]]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,[[\"@topic\"],[[30,1]]],null],[1,\"\\n\"]],[1,2]],null],[13]],[\"topic\",\"index\"],false,[\"raw\",\"each\",\"-track-array\",\"my-questions-topic-list-rejected-item\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/components/my-questions-topic-list-rejected.hbs",
    "isStrictMode": false
  });
});