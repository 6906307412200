define("discourse/plugins/uge-discourse-plugin/discourse/initializers/question-hub-dynamic-route-builders", ["exports", "@ember/string", "discourse/plugins/uge-discourse-plugin/discourse/routes/build-all-questions-route", "discourse/plugins/uge-discourse-plugin/discourse/routes/build-my-questions-route"], function (_exports, _string, _buildAllQuestionsRoute, _buildMyQuestionsRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "question-hub-dynamic-route-builders",
    initialize(_, app) {
      app.register("route:question-hub.index", (0, _buildAllQuestionsRoute.default)({
        filter: "default"
      }));
      app.register("route:question-hub.all-questions", (0, _buildAllQuestionsRoute.default)({
        filter: "default"
      }));
      app.register("route:question-hub.my-questions", (0, _buildMyQuestionsRoute.default)({
        filter: "default"
      }));
      app.register("route:question-hub.my-questions-posted", (0, _buildMyQuestionsRoute.default)({
        filter: "default"
      }));
    }
  };
});