define("discourse/plugins/uge-discourse-plugin/discourse/adapters/my-questions-topic-list", ["exports", "discourse/adapters/rest", "discourse/lib/ajax", "discourse/lib/preload-store"], function (_exports, _rest, _ajax, _preloadStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.finderFor = finderFor;
  function finderFor(filter, params) {
    return function () {
      let url = params.username ? `/topics/created-by/${params.username}.json` : `/${filter}.json`;
      if (params) {
        const urlSearchParams = new URLSearchParams();
        for (const [key, value] of Object.entries(params)) {
          if (typeof value === "undefined") {
            continue;
          }
          if (Array.isArray(value)) {
            for (const arrayValue of value) {
              urlSearchParams.append(`${key}[]`, arrayValue);
            }
          } else {
            urlSearchParams.set(key, value);
          }
        }
        const queryString = urlSearchParams.toString();
        if (queryString) {
          url += `?${queryString}`;
        }
      }
      return (0, _ajax.ajax)(url);
    };
  }
  var _default = _exports.default = _rest.default.extend({
    find(store, type, _ref) {
      let {
        filter,
        params
      } = _ref;
      return _preloadStore.default.getAndRemove("topic_list", finderFor(filter, params)).then(result => {
        result.filter = filter;
        result.params = params;
        return result;
      });
    }
  });
});