define("discourse/plugins/uge-discourse-plugin/discourse/templates/components/request-questions-filter-bar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="question-hub-filter-bar">
    <div class="filter-sort-wrapper">
      <div>
        <ComboBox
          @value={{this.resolvedOrder}}
          @content={{this.ugeListOrders}}
          @onChange={{this.onChangeOrder}}
          @options={{hash caretDownIcon="caret-down" caretUpIcon="caret-down"}}
        />
      </div>
    </div>
  </div>
  */
  {
    "id": "e13xEyOd",
    "block": "[[[10,0],[14,0,\"question-hub-filter-bar\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"filter-sort-wrapper\"],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@value\",\"@content\",\"@onChange\",\"@options\"],[[30,0,[\"resolvedOrder\"]],[30,0,[\"ugeListOrders\"]],[30,0,[\"onChangeOrder\"]],[28,[37,1],null,[[\"caretDownIcon\",\"caretUpIcon\"],[\"caret-down\",\"caret-down\"]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"combo-box\",\"hash\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/components/request-questions-filter-bar.hbs",
    "isStrictMode": false
  });
});