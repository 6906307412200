define("discourse/plugins/uge-discourse-plugin/discourse/components/question-hub-sidebar", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "discourse-common/utils/decorators", "discourse/models/category", "discourse/models/topic", "discourse/lib/ajax"], function (_exports, _component, _tracking, _service, _decorators, _category, _topic, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let _class = _exports.default = (_class2 = class _class2 extends _component.default {
    constructor() {
      super(...arguments);

      //Get current category
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "appEvents", _descriptor2, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor3, this);
      _initializerDefineProperty(this, "currentUser", _descriptor4, this);
      _initializerDefineProperty(this, "messageBus", _descriptor5, this);
      _initializerDefineProperty(this, "category", _descriptor6, this);
      _initializerDefineProperty(this, "requestCount", _descriptor7, this);
      const categoryFromParams = this.router.currentRoute?.params?.category_slug_path_with_id;
      if (categoryFromParams) {
        this.category = _category.default.findBySlugPathWithID(categoryFromParams);
        this.getRequestCount();
      } else {
        const topicId = this.router.currentRoute?.parent?.params?.id;
        if (topicId) {
          _topic.default.find(topicId, {}).then(topic => {
            if (topic.category_id) {
              this.category = _category.default.findById(topic.category_id);
              this.getRequestCount();
            }
          });
        }
      }
      this.appEvents.on("question-hub:refetch-request-count", this.getRequestCount);
      if (this.category) {
        this.messageBus.subscribe(`/reviewable_claimed/${this.category.id}`, data => {
          this.appEvents.trigger("question-hub:refetch-request-count");
        });
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off("composer:opened", this.getRequestCount);
    }
    async getRequestCount() {
      if (this.currentUser && this.currentUser.staff || this.category.organizer_id && this.category.organizer_id.includes(this.currentUser.id)) {
        const res = await (0, _ajax.ajax)(`/review/pending-count.json?category_id=${this.category.id}`);
        if (res) {
          this.requestCount = res.count;
          if (this.router?.currentRouteName?.includes("request")) {
            this.router.refresh();
          }
        }
      }
    }
    get tournamentUrl() {
      return this.siteSettings.uge_url + "/tournament/" + this.category?.tournament_id;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "appEvents", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "messageBus", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "category", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "requestCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "getRequestCount", [_decorators.bind], Object.getOwnPropertyDescriptor(_class2.prototype, "getRequestCount"), _class2.prototype)), _class2);
});