define("discourse/plugins/uge-discourse-plugin/discourse/templates/components/switch-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.currentUser}}
    <div class="switch-locale-button">
      <ComboBox
        @value={{this.currentLocale}}
        @content={{this.listLocale}}
        @onChange={{this.onChangeLocale}}
        @options={{hash caretDownIcon="caret-down" caretUpIcon="caret-down"}}
      />
    </div>
  {{/if}}
  */
  {
    "id": "ajLbPW3p",
    "block": "[[[41,[30,0,[\"currentUser\"]],[[[1,\"  \"],[10,0],[14,0,\"switch-locale-button\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@value\",\"@content\",\"@onChange\",\"@options\"],[[30,0,[\"currentLocale\"]],[30,0,[\"listLocale\"]],[30,0,[\"onChangeLocale\"]],[28,[37,2],null,[[\"caretDownIcon\",\"caretUpIcon\"],[\"caret-down\",\"caret-down\"]]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"combo-box\",\"hash\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/components/switch-button.hbs",
    "isStrictMode": false
  });
});