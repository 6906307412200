define("discourse/plugins/uge-discourse-plugin/discourse/templates/components/question-hub-tab-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <a
    {{on "click" this.onClickTab}}
    class={{concat-class
      "tab-item"
      (if @isSubTab "sub-tab")
      (if (eq @tab.id @tabActive) "tab-active" "tab-deactive")
    }}
    href={{this.href}}
  >{{this.label}}</a>
  */
  {
    "id": "6WtrIaMw",
    "block": "[[[11,3],[16,0,[28,[37,0],[\"tab-item\",[52,[30,1],\"sub-tab\"],[52,[28,[37,2],[[30,2,[\"id\"]],[30,3]],null],\"tab-active\",\"tab-deactive\"]],null]],[16,6,[30,0,[\"href\"]]],[4,[38,3],[\"click\",[30,0,[\"onClickTab\"]]],null],[12],[1,[30,0,[\"label\"]]],[13]],[\"@isSubTab\",\"@tab\",\"@tabActive\"],false,[\"concat-class\",\"if\",\"eq\",\"on\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/components/question-hub-tab-item.hbs",
    "isStrictMode": false
  });
});