define("discourse/plugins/uge-discourse-plugin/discourse/components/modal/delete-question-confirm", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse-i18n", "discourse/lib/ajax", "discourse/plugins/uge-discourse-plugin/discourse/utils/constants", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _discourseI18n, _ajax, _constants, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{""}}
    @closeModal={{@closeModal}}
    class="delete-question-confirm-modal"
    @flash={{this.flash}}
  >
    <:body>
    <img src="/plugins/uge-discourse-plugin/images/trash.png">
      <p>
        {{i18n
          "uge_discourse_plugin.my_question_hubs.delete_modal_content"
        }}
      </p>
    </:body>
    <:footer>
      <DButton
        @action={{this.deleteQuestion}}
        @disabled={{this.deletingQuestion}}
        @label="uge_discourse_plugin.my_question_hubs.delete_modal_yes_button"
        class="btn-danger"
      />
      <DButton
        @action={{@closeModal}}
        @label="uge_discourse_plugin.my_question_hubs.delete_modal_no_button"
        class="btn-primary"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "6VaaEBAE",
    "block": "[[[8,[39,0],[[24,0,\"delete-question-confirm-modal\"]],[[\"@title\",\"@closeModal\",\"@flash\"],[\"\",[30,1],[30,0,[\"flash\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/plugins/uge-discourse-plugin/images/trash.png\"],[12],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"uge_discourse_plugin.my_question_hubs.delete_modal_content\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,2],[[24,0,\"btn-danger\"]],[[\"@action\",\"@disabled\",\"@label\"],[[30,0,[\"deleteQuestion\"]],[30,0,[\"deletingQuestion\"]],\"uge_discourse_plugin.my_question_hubs.delete_modal_yes_button\"]],null],[1,\"\\n    \"],[8,[39,2],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,1],\"uge_discourse_plugin.my_question_hubs.delete_modal_no_button\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"d-button\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/components/modal/delete-question-confirm.hbs",
    "isStrictMode": false
  });
  let DeleteQuestionConfirm = _exports.default = (_class = class DeleteQuestionConfirm extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "deletingQuestion", _descriptor4, this);
      _initializerDefineProperty(this, "flash", _descriptor5, this);
    }
    async deleteQuestion() {
      try {
        this.deletingTopic = true;
        if (this.args.model.type === _constants.MY_QUESTION_TYPE.PENDING || this.args.model.type === _constants.MY_QUESTION_TYPE.REJECTED) {
          await (0, _ajax.ajax)(`/review/my/${this.args.model.topic.id}`, {
            type: "DELETE"
          });
        } else if (this.args.model.type === _constants.MY_QUESTION_TYPE.POSTED) {
          await this.args.model.topic.destroy(this.currentUser);
        }
        this.args.closeModal();
        this.router.refresh();
      } catch (e) {
        console.error(e);
        this.flash = _discourseI18n.default.t("post.controls.delete_topic_error");
        this.deletingQuestion = false;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "deletingQuestion", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "flash", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "deleteQuestion", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteQuestion"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DeleteQuestionConfirm);
});