define("discourse/plugins/uge-discourse-plugin/discourse/components/all-questions-topic-list-item", ["exports", "discourse/components/topic-list-item", "@ember-decorators/object", "discourse-common/lib/raw-templates", "@ember/template", "@ember/runloop", "discourse-common/lib/raw-handlebars-helpers", "discourse/lib/intercept-click", "discourse/lib/ajax"], function (_exports, _topicListItem, _object, _rawTemplates, _template, _runloop, _rawHandlebarsHelpers, _interceptClick, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let AllQuestionsTopicListItem = _exports.default = (_dec = (0, _object.observes)("topic.pinned", "expandGloballyPinned", "expandAllPinned"), (_class = class AllQuestionsTopicListItem extends _topicListItem.default {
    renderTopicListItem() {
      const template = (0, _rawTemplates.findRawTemplate)("list/all-questions-topic-list-item");
      if (template) {
        this.set("topicListItemContents", (0, _template.htmlSafe)(template(this, _rawHandlebarsHelpers.RUNTIME_OPTIONS)));
        (0, _runloop.schedule)("afterRender", () => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }
          if (this.selected && this.selected.includes(this.topic)) {
            this.element.querySelector("input.bulk-select").checked = true;
          }
          if (this._shouldFocusLastVisited()) {
            const title = this._titleElement();
            if (title) {
              title.addEventListener("focus", this._onTitleFocus);
              title.addEventListener("blur", this._onTitleBlur);
            }
          }
        });
      }
    }
    click(e) {
      const result = this.showEntrance(e);
      if (result === false) {
        return result;
      }
      const topic = this.topic;
      const target = e.target;
      const classList = target.classList;
      if (classList.contains("bulk-select")) {
        const selected = this.selected;
        if (target.checked) {
          selected.addObject(topic);
          if (this.lastChecked && e.shiftKey) {
            const bulkSelects = Array.from(document.querySelectorAll("input.bulk-select")),
              from = bulkSelects.indexOf(target),
              to = bulkSelects.findIndex(el => el.id === this.lastChecked.id),
              start = Math.min(from, to),
              end = Math.max(from, to);
            bulkSelects.slice(start, end).filter(el => el.checked !== true).forEach(checkbox => {
              checkbox.click();
            });
          }
          this.set("lastChecked", target);
        } else {
          selected.removeObject(topic);
          this.set("lastChecked", null);
        }
      }
      if (classList.contains("raw-topic-link") || classList.contains("post-activity")) {
        if ((0, _interceptClick.wantsNewWindow)(e)) {
          return true;
        }
        e.preventDefault();
        return this.navigateToTopic(topic, target.getAttribute("href"));
      }

      // make full row click target on mobile, due to size constraints
      if (this.site.mobileView && e.target.matches(".topic-list-data, .main-link, .right, .topic-item-stats, .topic-item-stats__category-tags, .discourse-tags")) {
        if ((0, _interceptClick.wantsNewWindow)(e)) {
          return true;
        }
        e.preventDefault();
        return this.navigateToTopic(topic, topic.lastUnreadUrl);
      }
      if (classList.contains("d-icon-thumbtack")) {
        let enablePin = true;
        if (topic.pinned) {
          enablePin = false;
        }
        (0, _ajax.ajax)(`/t/${topic.slug}/${topic.id}/status`, {
          type: "PUT",
          data: {
            status: "pinned",
            enabled: enablePin
          }
        }).then(() => {
          this.router.refresh();
        });
        return false;
      }
      return this.unhandledRowClick(e, topic);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "renderTopicListItem", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "renderTopicListItem"), _class.prototype)), _class));
});