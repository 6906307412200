define("discourse/plugins/uge-discourse-plugin/discourse/components/search-questions", ["exports", "discourse/components/search-menu/search-term", "@ember/object", "@glimmer/tracking", "@ember/service", "@ember/component", "discourse/lib/ajax", "discourse/plugins/uge-discourse-plugin/discourse/helpers/avatar-image"], function (_exports, _searchTerm, _object, _tracking, _service, _component, _ajax, _avatarImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.transformSearchResult = _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const transformSearchResult = (listSearchResult, searchTopicItem) => {
    let temp = searchTopicItem;
    const authorTemp = listSearchResult.posts.find(p => p.topic_id === searchTopicItem.id);
    temp.author = {
      username: authorTemp.username,
      name: authorTemp.name,
      url: "/u/" + authorTemp.username,
      avatar_template: (0, _avatarImage.avatarUrl)(authorTemp.avatar_template, "96")
    };
    temp.topicUrl = `/t/${temp.slug}/${temp.id}`;
    return temp;
  };
  _exports.transformSearchResult = transformSearchResult;
  let SearchQuestions = _exports.default = (_class = class SearchQuestions extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "resultResponse", _descriptor3, this);
      _initializerDefineProperty(this, "showSearchResult", _descriptor4, this);
      _initializerDefineProperty(this, "changeSearchButton", _descriptor5, this);
    }
    onKeyup(e) {
      var searchInputValue = $("#searchInput").val();
      if (!searchInputValue) {
        this.showSearchResult = false;
        this.resultResponse = null;
        this.changeSearchButton = true;
      }
      let initSearchInputValue = "";
      if (this.router?.currentRoute?.name === "question-hub.my-questions") {
        initSearchInputValue = `#${this.currentCategory.slug} @${this.currentUser.username} `;
      } else if (this.router?.currentRoute?.name === "question-hub.all-questions") {
        initSearchInputValue = `#${this.currentCategory.slug} `;
      }
      if (e.key === "Enter") {
        this.changeSearchButton = false;
        initSearchInputValue += searchInputValue;
        const query = `q=${encodeURIComponent(initSearchInputValue)}&page=1`;
        (0, _ajax.ajax)(`/search.json?${query}`).then(res => {
          if (res?.topics?.length > 0) {
            const tmpResultResponse = res.topics.map(searchItem => {
              return transformSearchResult(res, searchItem);
            });
            this.resultResponse = tmpResultResponse;
          } else {
            this.resultResponse = null;
          }
          this.showSearchResult = true;
        });
      }
    }
    onClearSearch() {
      this.changeSearchButton = true;
      this.showSearchResult = false;
      $("#searchInput").val("");
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "resultResponse", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showSearchResult", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "changeSearchButton", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onKeyup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClearSearch", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClearSearch"), _class.prototype)), _class);
});