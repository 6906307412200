define("discourse/plugins/uge-discourse-plugin/discourse/utils/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TYPE_TOPIC_DETAILS_MODAL = _exports.PLUGIN_ID = _exports.MY_QUESTION_TYPE = void 0;
  const PLUGIN_ID = _exports.PLUGIN_ID = "uge_discourse_plugin";
  const MY_QUESTION_TYPE = _exports.MY_QUESTION_TYPE = {
    POSTED: "posted",
    PENDING: "pending",
    REJECTED: "rejected"
  };
  const TYPE_TOPIC_DETAILS_MODAL = _exports.TYPE_TOPIC_DETAILS_MODAL = {
    ACTION: "action",
    ONLY_VIEW: "view"
  };
});