define("discourse/plugins/uge-discourse-plugin/discourse/services/question-hub", ["exports", "@ember/service", "@glimmer/tracking", "discourse/models/category", "discourse/models/topic", "discourse/lib/ajax"], function (_exports, _service, _tracking, _category, _topic, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let _class = _exports.default = (_class2 = class _class2 extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "loading", false);
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    getCurrentCategory() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      // if (this.currentCategory) {
      //   return this.currentCategory;
      // }

      //Get current category
      const categoryFromParams = this.router.currentRoute?.params?.category_slug_path_with_id;
      if (categoryFromParams) {
        const currentCategory = _category.default.findBySlugPathWithID(categoryFromParams);
        this.getRequestCount();
      } else {
        const topicId = this.router.currentRoute?.parent?.params?.id;
        if (topicId) {
          _topic.default.find(topicId, {}).then(topic => {
            if (topic.category_id) {
              this.currentCategory = _category.default.findById(topic.category_id);
              this.getRequestCount();
            }
          });
        }
      }
      return this.category;
    }
    async getRequestCount() {
      if (this.currentUser && this.currentUser.staff || this.category.organizer_id && this.category.organizer_id.includes(this.currentUser.id)) {
        const res = await (0, _ajax.ajax)(`/review/pending-count.json?category_id=${this.category.id}`);
        if (res) {
          this.requestCount = res.count;
        }
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2);
});