define("discourse/plugins/uge-discourse-plugin/discourse/components/request-topic-list", ["exports", "discourse/components/topic-list"], function (_exports, _topicList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RequestTopicList extends _topicList.default {
    _updateLastVisitedTopic(topics, order, ascending, top) {
      return;
    }
    click(e) {
      const target = e.target;
      const classList = target.classList;
      const totalCheckBox = document.querySelectorAll("input.bulk-select").length;
      const bulkSelectAllTopicCheckbox = document.getElementById("bulk-select-all-topic");
      const totalCheckedCheckbox = document.querySelectorAll("input.bulk-select:checked").length;
      const isChecked = bulkSelectAllTopicCheckbox.checked;
      if (classList.contains("bulk-select-all-topic")) {
        if (target.checked) {
          document.querySelectorAll("input.bulk-select:not(:checked)").forEach(el => el.click());
        } else if (totalCheckedCheckbox === totalCheckBox) {
          document.querySelectorAll("input.bulk-select:checked").forEach(el => el.click());
        }
      } else if (classList.contains("bulk-select")) {
        if (isChecked && totalCheckedCheckbox < totalCheckBox || !isChecked && totalCheckedCheckbox === totalCheckBox) {
          document.querySelector("input.bulk-select-all-topic").click();
        }
      }
    }
  }
  _exports.default = RequestTopicList;
});