define("discourse/plugins/uge-discourse-plugin/discourse/templates/question-hub/request", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <RequestQuestionsFilterBar
    @category={{this.model.category}}
    @ugeListOrders={{this.ugeListOrders}}
    @resolvedOrder={{this.resolvedOrder}}
    @onChangeOrder={{this.onChangeOrder}}
  />
  
  <Discovery::Layout
    @model={{this.model}}
  >
    <:list>
      <RequestTopics
        @model={{this.model.list}}
      />
    </:list>
  </Discovery::Layout>
  */
  {
    "id": "+qfIY3J2",
    "block": "[[[8,[39,0],null,[[\"@category\",\"@ugeListOrders\",\"@resolvedOrder\",\"@onChangeOrder\"],[[30,0,[\"model\",\"category\"]],[30,0,[\"ugeListOrders\"]],[30,0,[\"resolvedOrder\"]],[30,0,[\"onChangeOrder\"]]]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@model\"],[[30,0,[\"model\"]]]],[[\"list\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@model\"],[[30,0,[\"model\",\"list\"]]]],null],[1,\"\\n  \"]],[]]]]]],[],false,[\"request-questions-filter-bar\",\"discovery/layout\",\"request-topics\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/question-hub/request.hbs",
    "isStrictMode": false
  });
});