define("discourse/plugins/uge-discourse-plugin/discourse/templates/connectors/before-header-panel/switch-button-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <SwitchButton />
  */
  {
    "id": "ZFZg11qC",
    "block": "[[[8,[39,0],null,null,null]],[],false,[\"switch-button\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/connectors/before-header-panel/switch-button-header.hbs",
    "isStrictMode": false
  });
});