define("discourse/plugins/uge-discourse-plugin/discourse/controllers/question-hub/list-all-questions", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/bulk-select-helper", "discourse/lib/filter-mode", "discourse/lib/implicit-injections", "discourse/lib/tracked-tools", "discourse-i18n"], function (_exports, _tracking, _controller, _object, _service, _bulkSelectHelper, _filterMode, _implicitInjections, _trackedTools, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addDiscoveryQueryParam = addDiscoveryQueryParam;
  _exports.queryParams = _exports.default = void 0;
  _exports.resetParams = resetParams;
  var _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  // Just add query params here to have them automatically passed to topic list filters.
  const queryParams = _exports.queryParams = {
    order: {
      replace: true,
      refreshModel: true
    },
    ascending: {
      replace: true,
      refreshModel: true,
      default: false
    },
    status: {
      replace: true,
      refreshModel: true
    },
    state: {
      replace: true,
      refreshModel: true
    },
    search: {
      replace: true,
      refreshModel: true
    },
    max_posts: {
      replace: true,
      refreshModel: true
    },
    min_posts: {
      replace: true,
      refreshModel: true
    },
    q: {
      replace: true,
      refreshModel: true
    },
    before: {
      replace: true,
      refreshModel: true
    },
    bumped_before: {
      replace: true,
      refreshModel: true
    },
    f: {
      replace: true,
      refreshModel: true
    },
    subset: {
      replace: true,
      refreshModel: true
    },
    period: {
      replace: true,
      refreshModel: true
    },
    topic_ids: {
      replace: true,
      refreshModel: true
    },
    group_name: {
      replace: true,
      refreshModel: true
    },
    tags: {
      replace: true,
      refreshModel: true
    },
    match_all_tags: {
      replace: true,
      refreshModel: true
    },
    no_subcategories: {
      replace: true,
      refreshModel: true
    },
    no_tags: {
      replace: true,
      refreshModel: true
    },
    exclude_tag: {
      replace: true,
      refreshModel: true
    },
    pinned: {
      replace: true,
      refreshModel: true
    }
  };
  function resetParams() {
    let skipParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    for (const [param, value] of Object.entries(queryParams)) {
      if (!skipParams.includes(param)) {
        this.controller.set(param, value.default);
      }
    }
  }
  function addDiscoveryQueryParam(p, opts) {
    queryParams[p] = opts;
  }
  let DiscoveryListController = _exports.default = (0, _implicitInjections.disableImplicitInjections)(_class = (_class2 = class DiscoveryListController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "composer", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
      _initializerDefineProperty(this, "site", _descriptor3, this);
      _initializerDefineProperty(this, "currentUser", _descriptor4, this);
      _initializerDefineProperty(this, "model", _descriptor5, this);
      _defineProperty(this, "queryParams", Object.keys(queryParams));
      _defineProperty(this, "bulkSelectHelper", new _bulkSelectHelper.default(this));
      for (const [name, info] of Object.entries(queryParams)) {
        (0, _trackedTools.defineTrackedProperty)(this, name, info.default);
      }
    }
    get canBulkSelect() {
      return this.currentUser?.canManageTopic || this.showDismissRead || this.showResetNew;
    }
    get showDismissRead() {
      return (0, _filterMode.filterTypeForMode)(this.model.list?.filter) === "unread" && this.model.list.get("topics.length") > 0;
    }
    get showResetNew() {
      return (0, _filterMode.filterTypeForMode)(this.model.list?.filter) === "new" && this.model.list?.get("topics.length") > 0;
    }
    get createTopicTargetCategory() {
      const {
        category
      } = this.model;
      if (category?.canCreateTopic) {
        return category;
      }
      if (this.siteSettings.default_subcategory_on_read_only_category) {
        return category?.subcategoryWithCreateTopicPermission;
      }
    }
    get createTopicDisabled() {
      // We are in a category route, but user does not have permission for the category
      return this.model.category && !this.createTopicTargetCategory;
    }
    get resolvedAscending() {
      return (this.ascending ?? this.model.list.get("params.ascending")) === "true";
    }
    get resolvedOrder() {
      return this.order ?? this.model.list.get("params.order") ?? "activity";
    }
    get ugeListOrders() {
      return [{
        id: "activity",
        name: _discourseI18n.default.t("uge_discourse_plugin.latest")
      }, {
        id: "created",
        name: _discourseI18n.default.t("uge_discourse_plugin.oldest")
      }, {
        id: "posts",
        name: _discourseI18n.default.t("uge_discourse_plugin.most_reply")
      }];
    }
    onChangeTag(tag) {
      this.tags = tag;
    }
    onChangeOrder(sortBy) {
      this.order = sortBy;
      if (sortBy === "created") {
        this.ascending = true;
      } else {
        this.ascending = false;
      }
    }
    createTopic() {
      this.composer.openNewTopic({
        category: this.createTopicTargetCategory,
        tags: [this.model.tag?.id, ...(this.model.additionalTags ?? [])].filter(Boolean).reject(t => ["none", "all"].includes(t)).join(","),
        preferDraft: true
      });
    }
    changePeriod(p) {
      this.period = p;
    }
    changeSort(sortBy) {
      if (sortBy === this.resolvedOrder) {
        this.ascending = !this.resolvedAscending;
      } else {
        this.ascending = false;
      }
      this.order = sortBy;
    }
    changeNewListSubset(subset) {
      this.subset = subset;
      this.model.list.updateNewListSubsetParam(subset);
    }
    toggleTagInfo() {
      this.toggleProperty("showTagInfo");
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "composer", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "model", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "onChangeTag", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onChangeTag"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onChangeOrder", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onChangeOrder"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createTopic", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "createTopic"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changePeriod", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "changePeriod"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changeSort", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "changeSort"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changeNewListSubset", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "changeNewListSubset"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleTagInfo", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleTagInfo"), _class2.prototype)), _class2)) || _class;
});