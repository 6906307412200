define("discourse/plugins/uge-discourse-plugin/discourse/templates/components/my-questions-discovery/rejected-topics", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DiscoveryRejectedTopicsList
    @model={{@model}}
    @incomingCount={{this.topicTrackingState.incomingCount}}
    @bulkSelectHelper={{@bulkSelectHelper}}
  >
    {{#if this.hasTopics}}
      <MyQuestionsTopicListRejected
        @highlightLastVisited={{true}}
        @topics={{@model}}
        @discoveryList={{true}}
        @focusLastVisitedTopic={{true}}
      />
    {{/if}}
  </DiscoveryRejectedTopicsList>
  
  {{#unless this.hasTopics}}
    <footer class="topic-list-bottom">
      <h3>
        {{i18n "uge_discourse_plugin.my_question_hubs.rejected.no_question"}}
      </h3>
    </footer>
  {{/unless}}
  */
  {
    "id": "xwJ6LbD0",
    "block": "[[[8,[39,0],null,[[\"@model\",\"@incomingCount\",\"@bulkSelectHelper\"],[[30,1],[30,0,[\"topicTrackingState\",\"incomingCount\"]],[30,2]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"hasTopics\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@highlightLastVisited\",\"@topics\",\"@discoveryList\",\"@focusLastVisitedTopic\"],[true,[30,1],true,true]],null],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\\n\"],[41,[51,[30,0,[\"hasTopics\"]]],[[[1,\"  \"],[10,\"footer\"],[14,0,\"topic-list-bottom\"],[12],[1,\"\\n    \"],[10,\"h3\"],[12],[1,\"\\n      \"],[1,[28,[35,4],[\"uge_discourse_plugin.my_question_hubs.rejected.no_question\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@model\",\"@bulkSelectHelper\"],false,[\"discovery-rejected-topics-list\",\"if\",\"my-questions-topic-list-rejected\",\"unless\",\"i18n\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/components/my-questions-discovery/rejected-topics.hbs",
    "isStrictMode": false
  });
});