define("discourse/plugins/uge-discourse-plugin/discourse/initializers/modify-component-sidebar", ["exports", "discourse/lib/plugin-api", "discourse/plugins/uge-discourse-plugin/discourse/utils/constants", "@ember/object", "@glimmer/tracking", "@ember/application"], function (_exports, _pluginApi, _constants, _object, _tracking, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function isShowQuestionHubSidebar(currentRouteName) {
    return currentRouteName.includes("question-hub") || currentRouteName.includes("topic.");
  }
  var _default = _exports.default = {
    name: "modify-component-sidebar",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        var _obj, _init, _obj2, _init2;
        api.modifyClass("component:sidebar", (_obj = {
          pluginId: _constants.PLUGIN_ID,
          isQuestionHub: true,
          registerListener(element) {
            this.appEvents.on("page:changed", this, this._routeChanged);
            const router = (0, _application.getOwner)(this).lookup("service:router");
            if (!isShowQuestionHubSidebar(router.currentRouteName)) {
              this.isQuestionHub = false;
            }
          },
          unregisterListener(element) {
            this.appEvents.off("page:changed", this, this._routeChanged);
          },
          _routeChanged(route) {
            if (isShowQuestionHubSidebar(route.currentRouteName)) {
              this.isQuestionHub = true;
            } else {
              this.isQuestionHub = false;
            }
          }
        }, (_applyDecoratedDescriptor(_obj, "isQuestionHub", [_tracking.tracked], (_init = Object.getOwnPropertyDescriptor(_obj, "isQuestionHub"), _init = _init ? _init.value : undefined, {
          enumerable: true,
          configurable: true,
          writable: true,
          initializer: function () {
            return _init;
          }
        }), _obj), _applyDecoratedDescriptor(_obj, "registerListener", [_object.action], Object.getOwnPropertyDescriptor(_obj, "registerListener"), _obj), _applyDecoratedDescriptor(_obj, "unregisterListener", [_object.action], Object.getOwnPropertyDescriptor(_obj, "unregisterListener"), _obj)), _obj));
        api.modifyClass("component:sidebar/hamburger-dropdown", (_obj2 = {
          pluginId: _constants.PLUGIN_ID,
          isQuestionHub: true,
          triggerRenderedAppEvent() {
            this.appEvents.trigger("sidebar-hamburger-dropdown:rendered");
            const router = (0, _application.getOwner)(this).lookup("service:router");
            if (!isShowQuestionHubSidebar(router.currentRouteName)) {
              this.isQuestionHub = false;
            }
          }
        }, (_applyDecoratedDescriptor(_obj2, "isQuestionHub", [_tracking.tracked], (_init2 = Object.getOwnPropertyDescriptor(_obj2, "isQuestionHub"), _init2 = _init2 ? _init2.value : undefined, {
          enumerable: true,
          configurable: true,
          writable: true,
          initializer: function () {
            return _init2;
          }
        }), _obj2), _applyDecoratedDescriptor(_obj2, "triggerRenderedAppEvent", [_object.action], Object.getOwnPropertyDescriptor(_obj2, "triggerRenderedAppEvent"), _obj2)), _obj2));
      });
    }
  };
});