define("discourse/plugins/uge-discourse-plugin/discourse/components/request-topics", ["exports", "discourse/components/discovery/topics", "@ember/object", "discourse/lib/ajax", "@ember/service"], function (_exports, _topics, _object, _ajax, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let RequestTopics = _exports.default = (_class = class RequestTopics extends _topics.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "appEvents", _descriptor, this);
    }
    get hasTopics() {
      return this.args.model.length > 0;
    }
    handleClickApprove() {
      try {
        const checkedCheckboxes = document.querySelectorAll("input.bulk-select:checked");
        const checkedIds = Array.from(checkedCheckboxes).map(checkbox => parseInt(checkbox.id.split("-")[2]));

        // api approve a topic: /review/{id}/perform/approve_post?version=0

        if (checkedIds.length > 0) {
          const ajaxPromises = checkedIds.map(id => (0, _ajax.ajax)(`/review/${id}/perform/approve_post?version=0`, {
            type: "PUT"
          }));
          Promise.all(ajaxPromises).then(() => {
            this.router.refresh();
            this.appEvents.trigger("question-hub:refetch-request-count");
            var message = document.getElementById("message-approve-success");
            message.classList.remove("hidden");
            setTimeout(function () {
              message.classList.add("hidden");
            }, 2000);
          }).catch(error => {
            console.error("Error occurred:", error);
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleClickApprove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleClickApprove"), _class.prototype)), _class);
});