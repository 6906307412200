define("discourse/plugins/uge-discourse-plugin/select-kit/addon/components/uge-tag-drop", ["exports", "select-kit/components/tag-drop", "discourse-common/lib/get-url"], function (_exports, _tagDrop, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _tagDrop.default.extend({
    selectKitOptions: {
      caretDownIcon: "caret-down",
      caretUpIcon: "caret-down"
    },
    getQuestionHubUrl(category, tag) {
      let url;
      if (category) {
        url = category.path.replace("/c/", "/tournament/");
        if (tag) {
          url = url + "?tags=" + tag.toUpperCase();
        }
      }
      return (0, _getUrl.default)(url || "/");
    },
    actions: {
      onChange(tagId, tag) {
        if (tagId === _tagDrop.NO_TAG_ID) {
          tagId = _tagDrop.NONE_TAG_ID;
        } else if (tagId === _tagDrop.ALL_TAGS_ID) {
          tagId = null;
        } else if (tag && tag.targetTagId) {
          tagId = tag.targetTagId;
        }
        this.onChangeTag(tagId);
      }
    }
  });
});