define("discourse/plugins/uge-discourse-plugin/discourse/components/my-questions-discovery/pending-topics", ["exports", "discourse/components/discovery/topics"], function (_exports, _topics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MyQuestionsDiscoveryPendingTopics extends _topics.default {
    get hasTopics() {
      return this.args.model.length > 0;
    }
  }
  _exports.default = MyQuestionsDiscoveryPendingTopics;
});