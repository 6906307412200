define("discourse/plugins/uge-discourse-plugin/discourse/raw-templates/my-questions-topic-list-rejected-header", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return alias3((lookupProperty(helpers, "raw-plugin-outlet") || depth0 && lookupProperty(depth0, "raw-plugin-outlet") || alias2).call(alias1, {
        "name": "raw-plugin-outlet",
        "hash": {
          "name": "topic-list-header-before"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 55
          }
        }
      })) + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "topic-list-header-column", {
        "name": "raw",
        "hash": {
          "name": "uge_discourse_plugin.all_question_hubs.question_collumn_title",
          "order": "default"
        },
        "hashTypes": {
          "name": "StringLiteral",
          "order": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0,
          "order": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 2
          }
        }
      })) + "\n" + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "topic-list-header-column", {
        "name": "raw",
        "hash": {
          "ariaLabel": (lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, "uge_discourse_plugin.my_question_hubs.pending.question_collumn_status", {
            "name": "i18n",
            "hash": {},
            "hashTypes": {},
            "hashContexts": {},
            "types": ["StringLiteral"],
            "contexts": [depth0],
            "data": data,
            "loc": {
              "start": {
                "line": 14,
                "column": 12
              },
              "end": {
                "line": 14,
                "column": 90
              }
            }
          }),
          "name": "uge_discourse_plugin.my_question_hubs.pending.question_collumn_status",
          "order": "posts",
          "number": "true",
          "sortable": "sortable"
        },
        "hashTypes": {
          "ariaLabel": "SubExpression",
          "name": "StringLiteral",
          "order": "StringLiteral",
          "number": "StringLiteral",
          "sortable": "PathExpression"
        },
        "hashContexts": {
          "ariaLabel": depth0,
          "name": depth0,
          "order": depth0,
          "number": depth0,
          "sortable": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 8,
            "column": 0
          },
          "end": {
            "line": 15,
            "column": 2
          }
        }
      })) + "\n" + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "topic-list-header-column", {
        "name": "raw",
        "hash": {
          "ariaLabel": (lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, "uge_discourse_plugin.all_question_hubs.question_collumn_created_at", {
            "name": "i18n",
            "hash": {},
            "hashTypes": {},
            "hashContexts": {},
            "types": ["StringLiteral"],
            "contexts": [depth0],
            "data": data,
            "loc": {
              "start": {
                "line": 22,
                "column": 12
              },
              "end": {
                "line": 22,
                "column": 87
              }
            }
          }),
          "name": "uge_discourse_plugin.all_question_hubs.question_collumn_created_at",
          "order": "activity",
          "number": "true",
          "sortable": "sortable"
        },
        "hashTypes": {
          "ariaLabel": "SubExpression",
          "name": "StringLiteral",
          "order": "StringLiteral",
          "number": "StringLiteral",
          "sortable": "PathExpression"
        },
        "hashContexts": {
          "ariaLabel": depth0,
          "name": depth0,
          "order": depth0,
          "number": depth0,
          "sortable": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 16,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 2
          }
        }
      })) + "\n\n<th class=\"delete-select topic-list-data\">\n  \n</th>" + alias3((lookupProperty(helpers, "raw-plugin-outlet") || depth0 && lookupProperty(depth0, "raw-plugin-outlet") || alias2).call(alias1, {
        "name": "raw-plugin-outlet",
        "hash": {
          "name": "topic-list-header-after"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 29,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 54
          }
        }
      }));
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/my-questions-topic-list-rejected-header", template, {
    core: true
  });
  var _default = _exports.default = template;
});