define("discourse/plugins/uge-discourse-plugin/discourse/templates/question-hub", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="not-mobile">
    <QuestionHubHeader />
  </div>
  <div class="not-desktop">
    <QuestionHubHeaderMobile />
  </div>
  {{outlet}}
  */
  {
    "id": "5gd1Lvzh",
    "block": "[[[10,0],[14,0,\"not-mobile\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"not-desktop\"],[12],[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"],[46,[28,[37,3],null,null],null,null,null]],[],false,[\"question-hub-header\",\"question-hub-header-mobile\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/question-hub.hbs",
    "isStrictMode": false
  });
});