define("discourse/plugins/uge-discourse-plugin/discourse/templates/components/question-hub-sidebar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="color-bg-4 w-full h-full">
    <div class="sidebar-tabs">
      <div class="paragraph-2 text-bold color-neutral-white">{{i18n
          "uge_discourse_plugin.question_hub"
        }}</div>
    </div>
    <div class="sidebar-contents">
      <div class="question-hub-info">
        <a class="avatar-wrapper" href={{this.tournamentUrl}}>
          <img
            src="{{if
              this.category.uploaded_logo.url
              this.category.uploaded_logo.url
              '/plugins/uge-discourse-plugin/images/question-hub-logo-56.png'
            }}"
            onerror="this.onerror=null;this.src='/plugins/uge-discourse-plugin/images/question-hub-logo-56.png';"
          />
  
        </a>
        <div class="info">
          <a
            href={{this.tournamentUrl}}
            class="paragraph-2 text-bold color-neutral-white text-start"
          >{{this.category.display_name}}</a>
          <div
            class="small-text-1 text-normal color-neutral-10 text-start"
          >{{this.category.game}}</div>
        </div>
  
      </div>
      <div class="divider-horizontal" />
      <QuestionHubTabs
        @category={{this.category}}
        @organizerId={{this.category.organizer_id}}
        @requestCount={{this.requestCount}}
      />
    </div>
  </div>
  */
  {
    "id": "NanX8jzw",
    "block": "[[[10,0],[14,0,\"color-bg-4 w-full h-full\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"sidebar-tabs\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"paragraph-2 text-bold color-neutral-white\"],[12],[1,[28,[35,0],[\"uge_discourse_plugin.question_hub\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"sidebar-contents\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"question-hub-info\"],[12],[1,\"\\n      \"],[10,3],[14,0,\"avatar-wrapper\"],[15,6,[30,0,[\"tournamentUrl\"]]],[12],[1,\"\\n        \"],[10,\"img\"],[15,\"src\",[29,[[52,[30,0,[\"category\",\"uploaded_logo\",\"url\"]],[30,0,[\"category\",\"uploaded_logo\",\"url\"]],\"/plugins/uge-discourse-plugin/images/question-hub-logo-56.png\"]]]],[14,\"onerror\",\"this.onerror=null;this.src='/plugins/uge-discourse-plugin/images/question-hub-logo-56.png';\"],[12],[13],[1,\"\\n\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"info\"],[12],[1,\"\\n        \"],[10,3],[15,6,[30,0,[\"tournamentUrl\"]]],[14,0,\"paragraph-2 text-bold color-neutral-white text-start\"],[12],[1,[30,0,[\"category\",\"display_name\"]]],[13],[1,\"\\n        \"],[10,0],[14,0,\"small-text-1 text-normal color-neutral-10 text-start\"],[12],[1,[30,0,[\"category\",\"game\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"divider-horizontal\"],[12],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@category\",\"@organizerId\",\"@requestCount\"],[[30,0,[\"category\"]],[30,0,[\"category\",\"organizer_id\"]],[30,0,[\"requestCount\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"if\",\"question-hub-tabs\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/components/question-hub-sidebar.hbs",
    "isStrictMode": false
  });
});