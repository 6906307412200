define("discourse/plugins/uge-discourse-plugin/discourse/templates/question-hub/my-questions-pending", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <Discovery::Layout
    @model={{this.model}}
  >
    <:list>
      <MyQuestionsDiscovery::PendingTopics
        @model={{this.model.list}}
      />
    </:list>
  </Discovery::Layout>
  */
  {
    "id": "PClKTxTh",
    "block": "[[[8,[39,0],null,[[\"@model\"],[[30,0,[\"model\"]]]],[[\"list\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@model\"],[[30,0,[\"model\",\"list\"]]]],null],[1,\"\\n  \"]],[]]]]]],[],false,[\"discovery/layout\",\"my-questions-discovery/pending-topics\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/question-hub/my-questions-pending.hbs",
    "isStrictMode": false
  });
});