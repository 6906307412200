define("discourse/plugins/uge-discourse-plugin/discourse/templates/components/sidebar/hamburger-dropdown", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{concat-class "hamburger-panel" (if this.isQuestionHub "question-hub")}}>
    <div
      class="revamped menu-panel drop-down"
      data-max-width="320"
      {{did-insert this.triggerRenderedAppEvent}}
    >
      <div class="panel-body">
        <div class="panel-body-contents">
          <div class="sidebar-hamburger-dropdown">
            {{#if this.isQuestionHub}}
              <QuestionHubSidebar />
            {{else}}
              {{#if this.sidebarState.showMainPanel}}
                <Sidebar::Sections
                  @currentUser={{this.currentUser}}
                  @collapsableSections={{this.collapsableSections}}
                  @panel={{this.sidebarState.currentPanel}}
                />
              {{else}}
                <Sidebar::ApiPanels
                  @currentUser={{this.currentUser}}
                  @collapsableSections={{this.collapsableSections}}
                />
              {{/if}}
              <Sidebar::Footer @tagName="" />
            {{/if}}
          </div>
        </div>
      </div>
    </div>
  </div>
  */
  {
    "id": "9sfz/mdq",
    "block": "[[[10,0],[15,0,[28,[37,0],[\"hamburger-panel\",[52,[30,0,[\"isQuestionHub\"]],\"question-hub\"]],null]],[12],[1,\"\\n  \"],[11,0],[24,0,\"revamped menu-panel drop-down\"],[24,\"data-max-width\",\"320\"],[4,[38,2],[[30,0,[\"triggerRenderedAppEvent\"]]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"panel-body\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"panel-body-contents\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"sidebar-hamburger-dropdown\"],[12],[1,\"\\n\"],[41,[30,0,[\"isQuestionHub\"]],[[[1,\"            \"],[8,[39,3],null,null,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"sidebarState\",\"showMainPanel\"]],[[[1,\"              \"],[8,[39,4],null,[[\"@currentUser\",\"@collapsableSections\",\"@panel\"],[[30,0,[\"currentUser\"]],[30,0,[\"collapsableSections\"]],[30,0,[\"sidebarState\",\"currentPanel\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"              \"],[8,[39,5],null,[[\"@currentUser\",\"@collapsableSections\"],[[30,0,[\"currentUser\"]],[30,0,[\"collapsableSections\"]]]],null],[1,\"\\n\"]],[]]],[1,\"            \"],[8,[39,6],null,[[\"@tagName\"],[\"\"]],null],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"concat-class\",\"if\",\"did-insert\",\"question-hub-sidebar\",\"sidebar/sections\",\"sidebar/api-panels\",\"sidebar/footer\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/components/sidebar/hamburger-dropdown.hbs",
    "isStrictMode": false
  });
});