define("discourse/plugins/uge-discourse-plugin/discourse/widgets/new-topic-button", ["virtual-dom", "discourse/widgets/widget", "discourse-i18n", "discourse/models/composer", "discourse-common/lib/icon-library", "discourse/models/category"], function (_virtualDom, _widget, _discourseI18n, _composer, _iconLibrary, _category) {
  "use strict";

  (0, _widget.createWidget)("uge-new-topic-button", {
    services: ["router"],
    html(atts) {
      if (!this.currentUser) return;
      let ntb_text = _discourseI18n.default.t("uge_discourse_plugin.create_question.create"),
        ntb_title = _discourseI18n.default.t("uge_discourse_plugin.create_question.create_question_title"),
        ntb_icon = "plus",
        ntb_button_class = "btn btn-default btn btn-icon-text",
        ntb_button_helper = "button#uge-new-create-topic",
        ntb_label_helper = "span",
        composerController = this.register.lookup("controller:composer");
      const createTopic = async () => {
        const categoryFromParams = this.router.currentRoute?.params?.category_slug_path_with_id;
        const category = this.siteSettings.lazy_load_categories ? await _category.default.asyncFindBySlugPathWithID(categoryFromParams) : _category.default.findBySlugPathWithID(categoryFromParams);
        composerController.open({
          action: _composer.CREATE_TOPIC,
          categoryId: category.id,
          draftKey: _composer.NEW_TOPIC_KEY
        });
      };
      return (0, _virtualDom.h)(ntb_button_helper, {
        className: ntb_button_class,
        title: ntb_title,
        onclick: createTopic
      }, [(0, _virtualDom.h)(ntb_label_helper, ntb_text), (0, _iconLibrary.iconNode)(ntb_icon)]);
    }
  });
});