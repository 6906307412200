define("discourse/plugins/uge-discourse-plugin/discourse/components/my-questions-topic-list-item", ["exports", "discourse/components/topic-list-item", "@ember-decorators/object", "discourse-common/lib/raw-templates", "@ember/template", "@ember/runloop", "discourse-common/lib/raw-handlebars-helpers", "discourse/models/composer", "@ember/service", "discourse/components/modal/delete-topic-confirm", "discourse/plugins/uge-discourse-plugin/discourse/components/modal/delete-question-confirm", "discourse/plugins/uge-discourse-plugin/discourse/utils/constants"], function (_exports, _topicListItem, _object, _rawTemplates, _template, _runloop, _rawHandlebarsHelpers, _composer, _service, _deleteTopicConfirm, _deleteQuestionConfirm, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AllQuestionsTopicListItem = _exports.default = (_dec = (0, _object.observes)("topic.pinned", "expandGloballyPinned", "expandAllPinned"), (_class = class AllQuestionsTopicListItem extends _topicListItem.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "composer", _descriptor, this);
      _initializerDefineProperty(this, "modal", _descriptor2, this);
    }
    renderTopicListItem() {
      const template = (0, _rawTemplates.findRawTemplate)("list/my-questions-topic-list-item");
      if (template) {
        this.set("topicListItemContents", (0, _template.htmlSafe)(template(this, _rawHandlebarsHelpers.RUNTIME_OPTIONS)));
        (0, _runloop.schedule)("afterRender", () => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }
          if (this.selected && this.selected.includes(this.topic)) {
            this.element.querySelector("input.bulk-select").checked = true;
          }
          if (this._shouldFocusLastVisited()) {
            const title = this._titleElement();
            if (title) {
              title.addEventListener("focus", this._onTitleFocus);
              title.addEventListener("blur", this._onTitleBlur);
            }
          }
        });
      }
    }
    click(e) {
      const topic = this.topic;
      const target = e.target;
      const classList = target.classList;
      if (classList.contains("edit-select")) {
        topic.firstPost().then(post => {
          if (!this.currentUser) {
            return this.dialog.alert(I18n.t("post.controls.edit_anonymous"));
          } else if (!post.can_edit) {
            return false;
          }
          const composer = this.composer;
          const composerModel = composer.get("model");
          let editingFirst = composerModel && (post.get("firstPost") || composerModel.get("editingFirstPost"));
          let editingSharedDraft = false;
          let draftsCategoryId = this.get("site.shared_drafts_category_id");
          if (draftsCategoryId && draftsCategoryId === topic.get("category.id")) {
            editingSharedDraft = post.get("firstPost");
          }
          const topicDraftKey = `topic_${topic.id}`;
          const opts = {
            post,
            action: editingSharedDraft ? _composer.default.EDIT_SHARED_DRAFT : _composer.default.EDIT,
            draftKey: topicDraftKey
          };
          if (editingSharedDraft) {
            opts.destinationCategoryId = topic.get("destination_category_id");
          }

          // Cancel and reopen the composer for the first post
          if (editingFirst) {
            composer.cancelComposer(opts).then(() => composer.open(opts));
          } else {
            composer.open(opts);
          }
        });
      }
      if (classList.contains("delete-select")) {
        this.modal.show(_deleteQuestionConfirm.default, {
          model: {
            topic: topic,
            type: _constants.MY_QUESTION_TYPE.POSTED
          }
        });
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "composer", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderTopicListItem", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "renderTopicListItem"), _class.prototype)), _class));
});