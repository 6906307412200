define("discourse/plugins/uge-discourse-plugin/discourse/templates/components/modal/delete-topic-confirm", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{""}}
    @closeModal={{@closeModal}}
    class="delete-question-confirm-modal"
    @flash={{this.flash}}
  >
    <:body>
    <img src="/plugins/uge-discourse-plugin/images/trash.png">
      <p>
        {{i18n
          "uge_discourse_plugin.my_question_hubs.delete_modal_content"
          count=this.siteSettings.min_topic_views_for_delete_confirm
        }}
      </p>
    </:body>
    <:footer>
      <DButton
        @action={{this.deleteTopic}}
        @disabled={{this.deletingTopic}}
        @label="uge_discourse_plugin.my_question_hubs.delete_modal_yes_button"
        class="btn-danger"
      />
      <DButton
        @action={{@closeModal}}
        @label="uge_discourse_plugin.my_question_hubs.delete_modal_no_button"
        class="btn-primary"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "KwzajE1D",
    "block": "[[[8,[39,0],[[24,0,\"delete-question-confirm-modal\"]],[[\"@title\",\"@closeModal\",\"@flash\"],[\"\",[30,1],[30,0,[\"flash\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/plugins/uge-discourse-plugin/images/trash.png\"],[12],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"uge_discourse_plugin.my_question_hubs.delete_modal_content\"],[[\"count\"],[[30,0,[\"siteSettings\",\"min_topic_views_for_delete_confirm\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,2],[[24,0,\"btn-danger\"]],[[\"@action\",\"@disabled\",\"@label\"],[[30,0,[\"deleteTopic\"]],[30,0,[\"deletingTopic\"]],\"uge_discourse_plugin.my_question_hubs.delete_modal_yes_button\"]],null],[1,\"\\n    \"],[8,[39,2],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,1],\"uge_discourse_plugin.my_question_hubs.delete_modal_no_button\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"d-button\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/components/modal/delete-topic-confirm.hbs",
    "isStrictMode": false
  });
});