define("discourse/plugins/uge-discourse-plugin/discourse/raw-templates/list/all-questions-topic-list-item", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "1": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "  <td class=\"bulk-select topic-list-data\">\n    <label for=\"bulk-select-" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.id", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 4,
            "column": 28
          },
          "end": {
            "line": 4,
            "column": 40
          }
        }
      })) + "\">\n      <input type=\"checkbox\" class=\"bulk-select\" id=\"bulk-select-" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.id", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 5,
            "column": 65
          },
          "end": {
            "line": 5,
            "column": 77
          }
        }
      })) + "\" />\n    </label>\n  </td>\n";
    },
    "3": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return container.escapeExpression((lookupProperty(helpers, "topic-featured-link") || depth0 && lookupProperty(depth0, "topic-featured-link") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "topic", {
        "name": "topic-featured-link",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 34,
            "column": 8
          },
          "end": {
            "line": 34,
            "column": 38
          }
        }
      }));
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return alias3((lookupProperty(helpers, "raw-plugin-outlet") || depth0 && lookupProperty(depth0, "raw-plugin-outlet") || alias2).call(alias1, {
        "name": "raw-plugin-outlet",
        "hash": {
          "name": "topic-list-before-columns"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 55
          }
        }
      })) + "\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "bulkSelectEnabled", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 2,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 7
          }
        }
      })) != null ? stack1 : "") + "\n<td class=\"main-link clearfix topic-list-data container\" colspan=\"1\">\n  <div class=\"topic-status column\">" + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "topic-status", {
        "name": "raw",
        "hash": {
          "defaultIcon": "thumbtack",
          "topic": "topic"
        },
        "hashTypes": {
          "defaultIcon": "StringLiteral",
          "topic": "PathExpression"
        },
        "hashContexts": {
          "defaultIcon": depth0,
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 12,
            "column": 4
          },
          "end": {
            "line": 12,
            "column": 63
          }
        }
      })) + "\n  </div>\n  <div class=\"topic-author-avatar column\">\n    <a\n      href=\"" + alias3(lookupProperty(helpers, "get").call(alias1, "topic.featuredUsers.0.user.path", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 16,
            "column": 12
          },
          "end": {
            "line": 16,
            "column": 49
          }
        }
      })) + "\"\n      data-user-card=\"" + alias3(lookupProperty(helpers, "get").call(alias1, "topic.featuredUsers.0.user.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 17,
            "column": 22
          },
          "end": {
            "line": 17,
            "column": 63
          }
        }
      })) + "\"\n      class=\"" + alias3(lookupProperty(helpers, "get").call(alias1, "topic.featuredUsers.0.extraClasses", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 18,
            "column": 13
          },
          "end": {
            "line": 18,
            "column": 53
          }
        }
      })) + "\"\n    >" + alias3((lookupProperty(helpers, "avatar") || depth0 && lookupProperty(depth0, "avatar") || alias2).call(alias1, "topic.featuredUsers.0", {
        "name": "avatar",
        "hash": {
          "imageSize": "small",
          "namePath": "user.name",
          "usernamePath": "user.username",
          "avatarTemplatePath": "user.avatar_template"
        },
        "hashTypes": {
          "imageSize": "StringLiteral",
          "namePath": "StringLiteral",
          "usernamePath": "StringLiteral",
          "avatarTemplatePath": "StringLiteral"
        },
        "hashContexts": {
          "imageSize": depth0,
          "namePath": depth0,
          "usernamePath": depth0,
          "avatarTemplatePath": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 19,
            "column": 5
          },
          "end": {
            "line": 25,
            "column": 8
          }
        }
      })) + "</a>\n  </div>\n  <div class=\"topic-title-content column\">" + alias3((lookupProperty(helpers, "raw-plugin-outlet") || depth0 && lookupProperty(depth0, "raw-plugin-outlet") || alias2).call(alias1, {
        "name": "raw-plugin-outlet",
        "hash": {
          "name": "topic-list-before-link"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 28,
            "column": 4
          },
          "end": {
            "line": 28,
            "column": 56
          }
        }
      })) + "\n    <span class=\"link-top-line\">" + alias3((lookupProperty(helpers, "raw-plugin-outlet") || depth0 && lookupProperty(depth0, "raw-plugin-outlet") || alias2).call(alias1, {
        "name": "raw-plugin-outlet",
        "hash": {
          "name": "topic-list-before-status"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 30,
            "column": 6
          },
          "end": {
            "line": 30,
            "column": 60
          }
        }
      })) + alias3((lookupProperty(helpers, "topic-link") || depth0 && lookupProperty(depth0, "topic-link") || alias2).call(alias1, "topic", {
        "name": "topic-link",
        "hash": {
          "class": "raw-link raw-topic-link"
        },
        "hashTypes": {
          "class": "StringLiteral"
        },
        "hashContexts": {
          "class": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 32,
            "column": 6
          },
          "end": {
            "line": 32,
            "column": 59
          }
        }
      })) + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.featured_link", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(3, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 33,
            "column": 6
          },
          "end": {
            "line": 35,
            "column": 14
          }
        }
      })) != null ? stack1 : "") + alias3((lookupProperty(helpers, "raw-plugin-outlet") || depth0 && lookupProperty(depth0, "raw-plugin-outlet") || alias2).call(alias1, {
        "name": "raw-plugin-outlet",
        "hash": {
          "name": "topic-list-after-title"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 36,
            "column": 6
          },
          "end": {
            "line": 36,
            "column": 58
          }
        }
      })) + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "list.unread-indicator", {
        "name": "raw",
        "hash": {
          "unreadClass": "unreadClass",
          "topicId": "topic.id",
          "includeUnreadIndicator": "includeUnreadIndicator"
        },
        "hashTypes": {
          "unreadClass": "PathExpression",
          "topicId": "PathExpression",
          "includeUnreadIndicator": "PathExpression"
        },
        "hashContexts": {
          "unreadClass": depth0,
          "topicId": depth0,
          "includeUnreadIndicator": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 37,
            "column": 6
          },
          "end": {
            "line": 42,
            "column": 9
          }
        }
      })) + "</span>\n\n  </div>\n</td>" + alias3((lookupProperty(helpers, "raw-plugin-outlet") || depth0 && lookupProperty(depth0, "raw-plugin-outlet") || alias2).call(alias1, {
        "name": "raw-plugin-outlet",
        "hash": {
          "name": "topic-list-after-main-link"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 48,
            "column": 0
          },
          "end": {
            "line": 48,
            "column": 56
          }
        }
      })) + "\n\n" + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "list.posts-count-column", {
        "name": "raw",
        "hash": {
          "topic": "topic"
        },
        "hashTypes": {
          "topic": "PathExpression"
        },
        "hashContexts": {
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 50,
            "column": 0
          },
          "end": {
            "line": 50,
            "column": 45
          }
        }
      })) + "\n\n" + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "list.all-questions-activity-column", {
        "name": "raw",
        "hash": {
          "tagName": "td",
          "class": "num topic-list-data",
          "topic": "topic"
        },
        "hashTypes": {
          "tagName": "StringLiteral",
          "class": "StringLiteral",
          "topic": "PathExpression"
        },
        "hashContexts": {
          "tagName": depth0,
          "class": depth0,
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 52,
            "column": 0
          },
          "end": {
            "line": 52,
            "column": 97
          }
        }
      })) + alias3((lookupProperty(helpers, "raw-plugin-outlet") || depth0 && lookupProperty(depth0, "raw-plugin-outlet") || alias2).call(alias1, {
        "name": "raw-plugin-outlet",
        "hash": {
          "name": "topic-list-after-columns"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 54,
            "column": 0
          },
          "end": {
            "line": 54,
            "column": 54
          }
        }
      }));
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/list/all-questions-topic-list-item", template, {
    core: true
  });
  var _default = _exports.default = template;
});