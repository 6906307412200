define("discourse/plugins/uge-discourse-plugin/discourse/initializers/modify-component-composer", ["exports", "discourse/lib/plugin-api", "discourse/plugins/uge-discourse-plugin/discourse/utils/constants", "discourse-common/utils/decorators", "discourse-i18n", "discourse-common/lib/icon-library", "@ember/object"], function (_exports, _pluginApi, _constants, _decorators, _discourseI18n, _iconLibrary, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: "modify-component-composer",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        var _dec, _obj;
        api.modifyClass("component:composer-editor", (_dec = (0, _decorators.default)("composer.reply", "composer.replyLength", "composer.missingReplyCharacters", "composer.minimumPostLength", "lastValidatedAt"), (_obj = {
          pluginId: _constants.PLUGIN_ID,
          validation(reply, replyLength, missingReplyCharacters, minimumPostLength, lastValidatedAt) {
            const postType = this.get("composer.post.post_type");
            if (postType === this.site.get("post_types.small_action")) {
              return;
            }
            let reason;
            if (replyLength < 1) {
              reason = _discourseI18n.default.t("composer.error.post_missing");
            } else if (missingReplyCharacters > 0) {
              reason = _discourseI18n.default.t("composer.error.post_length", {
                count: minimumPostLength
              });
              const tl = this.get("currentUser.trust_level");
              if ((tl === 0 || tl === 1) && !this._isNewTopic) {
                reason += "<br/>" + _discourseI18n.default.t("composer.error.try_like", {
                  heart: (0, _iconLibrary.iconHTML)("heart", {
                    label: _discourseI18n.default.t("likes_lowercase", {
                      count: 1
                    })
                  })
                });
              }
            } else if (replyLength > this.siteSettings.max_post_length) {
              reason = _discourseI18n.default.t("composer.error.max_lenght_body_question", {
                max: this.siteSettings.max_post_length,
                count: replyLength
              });
            }
            if (reason) {
              return _object.default.create({
                failed: true,
                reason,
                lastShownAt: lastValidatedAt
              });
            }
          }
        }, (_applyDecoratedDescriptor(_obj, "validation", [_dec], Object.getOwnPropertyDescriptor(_obj, "validation"), _obj)), _obj)));
      });
    }
  };
});