define("discourse/plugins/uge-discourse-plugin/discourse/routes/question-hub-create-question", ["exports", "@ember/runloop", "@ember/service", "discourse/lib/cookie", "discourse/models/category", "discourse/routes/discourse", "discourse-common/lib/get-url", "@ember/application"], function (_exports, _runloop, _service, _cookie, _category, _discourse, _getUrl, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let _class = _exports.default = (_class2 = class _class2 extends _discourse.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "composer", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor3, this);
      _initializerDefineProperty(this, "currentUser", _descriptor4, this);
      _initializerDefineProperty(this, "site", _descriptor5, this);
    }
    beforeModel(transition) {
      const category = this.parseCategoryFromTransition(transition);
      if (this.currentUser) {
        if (category) {
          // Using URL-based transition to avoid bug with dynamic segments and refreshModel query params
          // https://github.com/emberjs/ember.js/issues/16992
          this.router.replaceWith(`/tournament/${category.slug}/${category.id}`).followRedirects().then(() => {
            if (this.currentUser.can_create_topic) {
              this.openComposer({
                transition,
                category
              });
            }
          });
        }
      } else {
        if (category) {
          // cookie("destination_url", transition.intent.url);
          const returnPath = encodeURIComponent(window.location.href);
          this.router.replaceWith(`/tournament/${category.slug}/${category.id}`).followRedirects().then(e => (0, _runloop.next)(() => window.location = (0, _getUrl.default)("/session/sso?return_path=" + returnPath)));
        }
      }
    }
    openComposer(_ref) {
      let {
        transition,
        category
      } = _ref;
      (0, _runloop.next)(() => {
        this.composer.openNewTopic({
          title: transition.to.queryParams.title,
          body: transition.to.queryParams.body,
          category,
          tags: transition.to.queryParams.tags
        });
        this.composer.set("formTemplateInitialValues", transition.to.queryParams);
      });
    }
    parseCategoryFromTransition(transition) {
      let category;
      const categoryIdFromParams = transition.to.params.category_slug_path_with_id.split("/")[1];
      if (categoryIdFromParams) {
        category = _category.default.findById(categoryIdFromParams);
      } else if (transition.to.queryParams.category) {
        const splitCategory = transition.to.queryParams.category.split("/");
        category = this._getCategory(splitCategory[0], splitCategory[1], "nameLower");
        if (!category) {
          category = this._getCategory(splitCategory[0], splitCategory[1], "slug");
        }
      }
      return category;
    }
    _getCategory(mainCategory, subCategory, type) {
      let category;
      if (!subCategory) {
        category = this.site.categories.findBy(type, mainCategory.toLowerCase());
      } else {
        const categories = this.site.categories;
        const main = categories.findBy(type, mainCategory.toLowerCase());
        if (main) {
          category = categories.find(item => {
            return item && item[type] === subCategory.toLowerCase() && item.parent_category_id === main.id;
          });
        }
      }
      return category;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "composer", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2);
});