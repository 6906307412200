define("discourse/plugins/uge-discourse-plugin/discourse/routes/build-all-questions-route", ["exports", "@ember/object", "@ember/service", "discourse/plugins/uge-discourse-plugin/discourse/controllers/question-hub/list-all-questions", "discourse/lib/implicit-injections", "discourse/lib/preload-store", "discourse/lib/topic-list-tracker", "discourse/models/category", "discourse/models/category-list", "discourse/models/topic-list", "discourse/routes/build-topic-route", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _object, _service, _listAllQuestions, _implicitInjections, _preloadStore, _topicListTracker, _category, _categoryList, _topicList, _buildTopicRoute, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildAllQuestionRoute;
  var _dec, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AbstractCategoryRoute = (_dec = (0, _service.inject)("search"), (0, _implicitInjections.disableImplicitInjections)(_class = (_class2 = class AbstractCategoryRoute extends _discourse.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "composer", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "topicTrackingState", _descriptor5, this);
      _initializerDefineProperty(this, "searchService", _descriptor6, this);
      _initializerDefineProperty(this, "historyStore", _descriptor7, this);
      _defineProperty(this, "queryParams", _listAllQuestions.queryParams);
      _defineProperty(this, "templateName", "question-hub/list-all-questions");
      _defineProperty(this, "controllerName", "question-hub/list-all-questions");
    }
    async model(params, transition) {
      const category = this.siteSettings.lazy_load_categories ? await _category.default.asyncFindBySlugPathWithID(params.category_slug_path_with_id) : _category.default.findBySlugPathWithID(params.category_slug_path_with_id);
      if (!category) {
        this.router.replaceWith("/404");
        return;
      }
      if (this.routeConfig?.no_subcategories === undefined && category.default_list_filter === "none" && this.routeConfig?.filter === "default" && params) {
        // TODO: avoid throwing away preload data by redirecting on the server
        _preloadStore.default.getAndRemove("topic_list");
        this.router.replaceWith("discovery.categoryNone", params.category_slug_path_with_id);
        return;
      }
      const subcategoryListPromise = this._createSubcategoryList(category);
      const topicListPromise = this._retrieveTopicList(category, transition, params);
      const noSubcategories = !!this.routeConfig?.no_subcategories;
      const filterType = this.filter(category).split("/")[0];
      return {
        category,
        modelParams: params,
        subcategoryList: await subcategoryListPromise,
        list: await topicListPromise,
        noSubcategories,
        filterType
      };
    }
    filter(category) {
      return this.routeConfig?.filter === "default" ? category.get("default_view") || "latest" : this.routeConfig?.filter;
    }
    async _createSubcategoryList(category) {
      if (category.isParent && category.show_subcategory_list) {
        return _categoryList.default.listForParent(this.store, category);
      }
    }
    async _retrieveTopicList(category, transition, modelParams) {
      const findOpts = (0, _buildTopicRoute.filterQueryParams)(modelParams, this.routeConfig);
      if (modelParams && modelParams.pinned === "true") {
        findOpts.pinned = "true";
      } else {
        findOpts.pinned = undefined;
      }
      const extras = {
        cached: this.historyStore.isPoppedState
      };
      let listFilter = `c/${_category.default.slugFor(category)}/${category.id}`;
      if (findOpts.no_subcategories) {
        listFilter += "/none";
      }
      listFilter += `/l/${this.filter(category)}`;
      const topicList = await (0, _buildTopicRoute.findTopicList)(this.store, this.topicTrackingState, listFilter, findOpts, extras);
      _topicList.default.hideUniformCategory(topicList, category);
      return topicList;
    }
    titleToken() {
      const category = this.currentModel.category;
      const filterText = _discourseI18n.default.t("filters." + this.filter(category).replace("/", ".") + ".title");
      let categoryName = category.name;
      if (category.parent_category_id) {
        const list = _category.default.list();
        const parentCategory = list.findBy("id", category.parent_category_id);
        categoryName = `${parentCategory.name}/${categoryName}`;
      }
      return _discourseI18n.default.t("filters.with_category", {
        filter: filterText,
        category: categoryName
      });
    }
    setupController(controller, model) {
      super.setupController(...arguments);
      controller.bulkSelectHelper.clear();
      this.searchService.searchContext = model.category.get("searchContext");
      (0, _topicListTracker.setTopicList)(model.list);
      const p = model.category.params;
      if (p?.order !== undefined) {
        controller.order = p.order;
      }
      if (p?.ascending !== undefined) {
        controller.ascending = p.ascending;
      }
    }
    deactivate() {
      super.deactivate(...arguments);
      this.composer.set("prioritizedCategoryId", null);
      this.searchService.searchContext = null;
    }
    setNotification(notification_level) {
      this.currentModel.setNotification(notification_level);
    }
    triggerRefresh() {
      this.refresh();
    }
    resetParams() {
      let skipParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      _listAllQuestions.resetParams.call(this, skipParams);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "composer", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "topicTrackingState", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "searchService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "historyStore", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "setNotification", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "setNotification"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "triggerRefresh", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "triggerRefresh"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "resetParams", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "resetParams"), _class2.prototype)), _class2)) || _class); // A helper function to create a category route with parameters
  function buildAllQuestionRoute(routeConfig) {
    return class extends AbstractCategoryRoute {
      constructor() {
        super(...arguments);
        _defineProperty(this, "routeConfig", routeConfig);
      }
    };
  }
});