define("discourse/plugins/uge-discourse-plugin/discourse/templates/components/user-menu/profile-tab-content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul aria-labelledby={{@ariaLabelledby}}>
    <li class="logout">
      <DButton @action={{route-action "logout"}} class="btn-flat profile-tab-btn">
        {{d-icon "sign-out-alt"}}
        <span class="item-label">
          {{i18n "user.log_out"}}
        </span>
      </DButton>
    </li>
  </ul>
  */
  {
    "id": "9ZkcoI7H",
    "block": "[[[10,\"ul\"],[15,\"aria-labelledby\",[30,1]],[12],[1,\"\\n  \"],[10,\"li\"],[14,0,\"logout\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"btn-flat profile-tab-btn\"]],[[\"@action\"],[[28,[37,1],[\"logout\"],null]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[\"sign-out-alt\"],null]],[1,\"\\n      \"],[10,1],[14,0,\"item-label\"],[12],[1,\"\\n        \"],[1,[28,[35,3],[\"user.log_out\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@ariaLabelledby\"],false,[\"d-button\",\"route-action\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/components/user-menu/profile-tab-content.hbs",
    "isStrictMode": false
  });
});