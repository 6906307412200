define("discourse/plugins/uge-discourse-plugin/discourse/templates/question-hub/list-all-questions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <QuestionHubFilterBar
    @category={{this.model.category}}
    @tagId={{this.model.modelParams.tags}}
    @onChangeTag={{this.onChangeTag}}
    @ugeListOrders={{this.ugeListOrders}}
    @resolvedOrder={{this.resolvedOrder}}
    @onChangeOrder={{this.onChangeOrder}}
  />
  
  <Discovery::Layout
    @model={{this.model}}
    @createTopicDisabled={{this.createTopicDisabled}}
  >
    <:header>
      {{#if this.model.subcategoryList}}
        <Discovery::CategoriesDisplay
          @categories={{this.model.subcategoryList.categories}}
          @parentCategory={{this.model.subcategoryList.parentCategory}}
        />
      {{/if}}
      {{#if (and this.showTagInfo this.model.tag)}}
        <TagInfo @tag={{this.model.tag}} @list={{this.model.list}} />
      {{/if}}
    </:header>
  
    <:list>
      <AllQuestionsDiscovery::Topics
        @period={{this.model.list.for_period}}
        @changePeriod={{this.changePeriod}}
        @model={{this.model.list}}
        @canBulkSelect={{this.canBulkSelect}}
        @bulkSelectHelper={{this.bulkSelectHelper}}
        @showDismissRead={{this.showDismissRead}}
        @showResetNew={{this.showResetNew}}
        @category={{this.model.category}}
        @tag={{this.model.tag}}
        @changeSort={{this.changeSort}}
        @changeNewListSubset={{this.changeNewListSubset}}
      />
    </:list>
  </Discovery::Layout>
  */
  {
    "id": "yxW6H9cb",
    "block": "[[[8,[39,0],null,[[\"@category\",\"@tagId\",\"@onChangeTag\",\"@ugeListOrders\",\"@resolvedOrder\",\"@onChangeOrder\"],[[30,0,[\"model\",\"category\"]],[30,0,[\"model\",\"modelParams\",\"tags\"]],[30,0,[\"onChangeTag\"]],[30,0,[\"ugeListOrders\"]],[30,0,[\"resolvedOrder\"]],[30,0,[\"onChangeOrder\"]]]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@model\",\"@createTopicDisabled\"],[[30,0,[\"model\"]],[30,0,[\"createTopicDisabled\"]]]],[[\"header\",\"list\"],[[[[1,\"\\n\"],[41,[30,0,[\"model\",\"subcategoryList\"]],[[[1,\"      \"],[8,[39,3],null,[[\"@categories\",\"@parentCategory\"],[[30,0,[\"model\",\"subcategoryList\",\"categories\"]],[30,0,[\"model\",\"subcategoryList\",\"parentCategory\"]]]],null],[1,\"\\n\"]],[]],null],[41,[28,[37,4],[[30,0,[\"showTagInfo\"]],[30,0,[\"model\",\"tag\"]]],null],[[[1,\"      \"],[8,[39,5],null,[[\"@tag\",\"@list\"],[[30,0,[\"model\",\"tag\"]],[30,0,[\"model\",\"list\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]],[[[1,\"\\n    \"],[8,[39,6],null,[[\"@period\",\"@changePeriod\",\"@model\",\"@canBulkSelect\",\"@bulkSelectHelper\",\"@showDismissRead\",\"@showResetNew\",\"@category\",\"@tag\",\"@changeSort\",\"@changeNewListSubset\"],[[30,0,[\"model\",\"list\",\"for_period\"]],[30,0,[\"changePeriod\"]],[30,0,[\"model\",\"list\"]],[30,0,[\"canBulkSelect\"]],[30,0,[\"bulkSelectHelper\"]],[30,0,[\"showDismissRead\"]],[30,0,[\"showResetNew\"]],[30,0,[\"model\",\"category\"]],[30,0,[\"model\",\"tag\"]],[30,0,[\"changeSort\"]],[30,0,[\"changeNewListSubset\"]]]],null],[1,\"\\n  \"]],[]]]]]],[],false,[\"question-hub-filter-bar\",\"discovery/layout\",\"if\",\"discovery/categories-display\",\"and\",\"tag-info\",\"all-questions-discovery/topics\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/question-hub/list-all-questions.hbs",
    "isStrictMode": false
  });
});