define("discourse/plugins/uge-discourse-plugin/select-kit/addon/components/uge-single-select", ["exports", "@ember/object", "@ember/utils", "select-kit/components/select-kit", "@ember/object/computed", "@ember/service", "discourse-common/utils/decorators", "@ember/application"], function (_exports, _object, _utils, _selectKit, _computed, _service, _decorators, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _selectKit.default.extend((_dec = (0, _decorators.default)(), _dec2 = (0, _decorators.default)("router.currentRoute"), (_obj = {
    router: (0, _service.inject)(),
    pluginApiIdentifiers: ["combo-box"],
    classNames: ["combobox", "combo-box"],
    selectKitOptions: {
      caretUpIcon: "caret-up",
      caretDownIcon: "caret-down",
      autoFilterable: "autoFilterable",
      clearable: false,
      headerComponent: "combo-box/combo-box-header"
    },
    autoFilterable: (0, _computed.gte)("content.length", 10),
    singleSelect: true,
    selectedContent: (0, _object.computed)("value", "content.[]", "selectKit.noneItem", function () {
      if (!(0, _utils.isEmpty)(this.value)) {
        let content;
        const value = this.selectKit.options.castInteger && this._isNumeric(this.value) ? Number(this.value) : this.value;
        if (this.selectKit.valueProperty) {
          content = (this.content || []).findBy(this.selectKit.valueProperty, value);
          return this.selectKit.modifySelection(content || this.defaultItem(value, value));
        } else {
          return this.selectKit.modifySelection((this.content || []).filter(c => c === value));
        }
      } else {
        return this.selectKit.noneItem;
      }
    }),
    showPinnedTopicOption() {
      if (this?.router?.currentRouteName?.includes("all-questions") || this?.router?.currentRouteName === "question-hub.index") {
        return true;
      } else {
        return false;
      }
    },
    isCheckedShowPinnedTopicCheckbox() {
      if (this.router?.currentRoute?.queryParams?.pinned) {
        return true;
      } else {
        return false;
      }
    },
    click(e) {
      const target = e.target;
      const classList = target.classList;
      if (classList.contains("bulk-select-show-pinned-topic-name") || classList.contains("bulk-select-show-pinned-topic")) {
        if (classList.contains("bulk-select-show-pinned-topic-name")) {
          document.querySelector("input.bulk-select-show-pinned-topic").click();
        }
        const bulkSelectShowPinnedTopicCheckbox = document.getElementById("bulk-select-show-pinned-topic");
        const isChecked = bulkSelectShowPinnedTopicCheckbox.checked;
        const listAllQuestionController = (0, _application.getOwner)(this).lookup("controller:question-hub/list-all-questions");
        if (isChecked) {
          listAllQuestionController.pinned = "true";
        } else {
          listAllQuestionController.pinned = undefined;
        }
        this.selectKit.close();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "showPinnedTopicOption", [_dec], Object.getOwnPropertyDescriptor(_obj, "showPinnedTopicOption"), _obj), _applyDecoratedDescriptor(_obj, "isCheckedShowPinnedTopicCheckbox", [_dec2], Object.getOwnPropertyDescriptor(_obj, "isCheckedShowPinnedTopicCheckbox"), _obj)), _obj)));
});