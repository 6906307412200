define("discourse/plugins/uge-discourse-plugin/discourse/components/my-questions-topic-list-pending", ["exports", "discourse/components/topic-list"], function (_exports, _topicList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MyQuestionsTopicListPending extends _topicList.default {
    _updateLastVisitedTopic(topics, order, ascending, top) {
      return;
    }
  }
  _exports.default = MyQuestionsTopicListPending;
});