define("discourse/plugins/uge-discourse-plugin/discourse/routes/redirect-page", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _discourse.default {
    model() {
      return;
    }
  }
  _exports.default = _default;
});