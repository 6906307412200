define("discourse/plugins/uge-discourse-plugin/discourse/initializers/modify-component-menu", ["exports", "discourse/lib/plugin-api", "discourse/plugins/uge-discourse-plugin/discourse/utils/constants", "@glimmer/tracking", "discourse/components/user-menu/profile-tab-content"], function (_exports, _pluginApi, _constants, _tracking, _profileTabContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: "modify-component-menu",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        var _obj, _init, _init2;
        const DEFAULT_PANEL_COMPONENT = _profileTabContent.default;
        const DEFAULT_TAB_ID = "profile";
        api.modifyClass("component:user-menu/menu", (_obj = {
          pluginId: _constants.PLUGIN_ID,
          currentPanelComponent: DEFAULT_PANEL_COMPONENT,
          currentTabId: DEFAULT_TAB_ID
        }, (_applyDecoratedDescriptor(_obj, "currentPanelComponent", [_tracking.tracked], (_init = Object.getOwnPropertyDescriptor(_obj, "currentPanelComponent"), _init = _init ? _init.value : undefined, {
          enumerable: true,
          configurable: true,
          writable: true,
          initializer: function () {
            return _init;
          }
        }), _obj), _applyDecoratedDescriptor(_obj, "currentTabId", [_tracking.tracked], (_init2 = Object.getOwnPropertyDescriptor(_obj, "currentTabId"), _init2 = _init2 ? _init2.value : undefined, {
          enumerable: true,
          configurable: true,
          writable: true,
          initializer: function () {
            return _init2;
          }
        }), _obj)), _obj));
      });
    }
  };
});