define("discourse/plugins/uge-discourse-plugin/discourse/initializers/reopen-home-logo-widget", ["exports", "discourse/lib/plugin-api", "discourse-common/lib/get-url"], function (_exports, _pluginApi, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "reopen-home-logo-widget",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        api.reopenWidget("home-logo", {
          settings: {
            href: (0, _getUrl.default)(container.lookup("site-settings:main").uge_url || "/")
          }
        });
      });
    }
  };
});