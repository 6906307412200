define("discourse/plugins/uge-discourse-plugin/discourse/raw-templates/list/my-questions-activity-column", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<" + alias2(lookupProperty(helpers, "get").call(alias1, "tagName", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 1
          },
          "end": {
            "line": 1,
            "column": 12
          }
        }
      })) + " class=\"pending-activity\">\n  <a class=\"post-pending-activity\">" + alias2((lookupProperty(helpers, "custom-format-date") || depth0 && lookupProperty(depth0, "custom-format-date") || container.hooks.helperMissing).call(alias1, "topic.created_at", {
        "name": "custom-format-date",
        "hash": {
          "noTitle": "true",
          "format": "tiny"
        },
        "hashTypes": {
          "noTitle": "StringLiteral",
          "format": "StringLiteral"
        },
        "hashContexts": {
          "noTitle": depth0,
          "format": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 4
          },
          "end": {
            "line": 3,
            "column": 74
          }
        }
      })) + "</a>\n</" + alias2(lookupProperty(helpers, "get").call(alias1, "tagName", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 5,
            "column": 2
          },
          "end": {
            "line": 5,
            "column": 13
          }
        }
      })) + ">";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/list/my-questions-activity-column", template, {
    core: true
  });
  var _default = _exports.default = template;
});