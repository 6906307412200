define("discourse/plugins/uge-discourse-plugin/discourse/templates/components/my-questions-topic-list-pending-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <PluginOutlet
    @name="above-topic-list-item"
    @outletArgs={{hash topic=this.topic}}
  />
  {{this.topicListItemContents}}
  */
  {
    "id": "gSZUaeW6",
    "block": "[[[8,[39,0],null,[[\"@name\",\"@outletArgs\"],[\"above-topic-list-item\",[28,[37,1],null,[[\"topic\"],[[30,0,[\"topic\"]]]]]]],null],[1,\"\\n\"],[1,[30,0,[\"topicListItemContents\"]]]],[],false,[\"plugin-outlet\",\"hash\"]]",
    "moduleName": "discourse/plugins/uge-discourse-plugin/discourse/templates/components/my-questions-topic-list-pending-item.hbs",
    "isStrictMode": false
  });
});