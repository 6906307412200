define("discourse/plugins/uge-discourse-plugin/discourse/raw-templates/all-questions-topic-list-header", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "1": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "  <th class=\"bulk-select topic-list-data\">\n" + ((stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "canBulkSelect", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(2, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 4,
            "column": 4
          },
          "end": {
            "line": 6,
            "column": 11
          }
        }
      })) != null ? stack1 : "") + "  </th>\n";
    },
    "2": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "      " + container.escapeExpression((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "flat-button", {
        "name": "raw",
        "hash": {
          "title": "topics.bulk.toggle",
          "icon": "list",
          "class": "bulk-select"
        },
        "hashTypes": {
          "title": "StringLiteral",
          "icon": "StringLiteral",
          "class": "StringLiteral"
        },
        "hashContexts": {
          "title": depth0,
          "icon": depth0,
          "class": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 5,
            "column": 6
          },
          "end": {
            "line": 5,
            "column": 86
          }
        }
      })) + "\n";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return alias3((lookupProperty(helpers, "raw-plugin-outlet") || depth0 && lookupProperty(depth0, "raw-plugin-outlet") || alias2).call(alias1, {
        "name": "raw-plugin-outlet",
        "hash": {
          "name": "topic-list-header-before"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 55
          }
        }
      })) + ((stack1 = lookupProperty(helpers, "if").call(alias1, "bulkSelectEnabled", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 2,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 7
          }
        }
      })) != null ? stack1 : "") + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "topic-list-header-column", {
        "name": "raw",
        "hash": {
          "newTopicsCount": "newTopicsCount",
          "newRepliesCount": "newRepliesCount",
          "newListSubset": "newListSubset",
          "showTopicsAndRepliesToggle": "showTopicsAndRepliesToggle",
          "canDoBulkActions": "canDoBulkActions",
          "canBulkSelect": "canBulkSelect",
          "showBulkToggle": "toggleInTitle",
          "bulkSelectEnabled": "bulkSelectEnabled",
          "name": "uge_discourse_plugin.all_question_hubs.question_collumn_title",
          "order": "default"
        },
        "hashTypes": {
          "newTopicsCount": "PathExpression",
          "newRepliesCount": "PathExpression",
          "newListSubset": "PathExpression",
          "showTopicsAndRepliesToggle": "PathExpression",
          "canDoBulkActions": "PathExpression",
          "canBulkSelect": "PathExpression",
          "showBulkToggle": "PathExpression",
          "bulkSelectEnabled": "PathExpression",
          "name": "StringLiteral",
          "order": "StringLiteral"
        },
        "hashContexts": {
          "newTopicsCount": depth0,
          "newRepliesCount": depth0,
          "newListSubset": depth0,
          "showTopicsAndRepliesToggle": depth0,
          "canDoBulkActions": depth0,
          "canBulkSelect": depth0,
          "showBulkToggle": depth0,
          "bulkSelectEnabled": depth0,
          "name": depth0,
          "order": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 9,
            "column": 0
          },
          "end": {
            "line": 21,
            "column": 2
          }
        }
      })) + "\n" + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "topic-list-header-column", {
        "name": "raw",
        "hash": {
          "ariaLabel": (lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, "uge_discourse_plugin.all_question_hubs.question_collumn_reply", {
            "name": "i18n",
            "hash": {},
            "hashTypes": {},
            "hashContexts": {},
            "types": ["StringLiteral"],
            "contexts": [depth0],
            "data": data,
            "loc": {
              "start": {
                "line": 28,
                "column": 12
              },
              "end": {
                "line": 28,
                "column": 82
              }
            }
          }),
          "name": "uge_discourse_plugin.all_question_hubs.question_collumn_reply",
          "order": "posts",
          "number": "true",
          "sortable": "sortable"
        },
        "hashTypes": {
          "ariaLabel": "SubExpression",
          "name": "StringLiteral",
          "order": "StringLiteral",
          "number": "StringLiteral",
          "sortable": "PathExpression"
        },
        "hashContexts": {
          "ariaLabel": depth0,
          "name": depth0,
          "order": depth0,
          "number": depth0,
          "sortable": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 22,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 2
          }
        }
      })) + "\n" + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "topic-list-header-column", {
        "name": "raw",
        "hash": {
          "ariaLabel": (lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, "uge_discourse_plugin.all_question_hubs.question_collumn_created_at", {
            "name": "i18n",
            "hash": {},
            "hashTypes": {},
            "hashContexts": {},
            "types": ["StringLiteral"],
            "contexts": [depth0],
            "data": data,
            "loc": {
              "start": {
                "line": 36,
                "column": 12
              },
              "end": {
                "line": 36,
                "column": 87
              }
            }
          }),
          "name": "uge_discourse_plugin.all_question_hubs.question_collumn_created_at",
          "order": "activity",
          "number": "true",
          "sortable": "sortable"
        },
        "hashTypes": {
          "ariaLabel": "SubExpression",
          "name": "StringLiteral",
          "order": "StringLiteral",
          "number": "StringLiteral",
          "sortable": "PathExpression"
        },
        "hashContexts": {
          "ariaLabel": depth0,
          "name": depth0,
          "order": depth0,
          "number": depth0,
          "sortable": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 30,
            "column": 0
          },
          "end": {
            "line": 37,
            "column": 2
          }
        }
      })) + alias3((lookupProperty(helpers, "raw-plugin-outlet") || depth0 && lookupProperty(depth0, "raw-plugin-outlet") || alias2).call(alias1, {
        "name": "raw-plugin-outlet",
        "hash": {
          "name": "topic-list-header-after"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 38,
            "column": 0
          },
          "end": {
            "line": 38,
            "column": 54
          }
        }
      }));
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/all-questions-topic-list-header", template, {
    core: true
  });
  var _default = _exports.default = template;
});